import React, { useEffect, useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AppContext } from "./context/appContext";
import { ToastContainer } from "react-toastify";

import Header from "./components/global/header/header";
import Footer from "./components/global/footer/footer";

import PrivateRoute from "./components/privateRoute/privateRoute";
import Shop from "./components/pages/shop/shop";
import PaymentDetails from "./components/pages/paymentDetails/paymentDetails";
import OrderConfirmation from "./components/pages/orderConfirmation/orderConfirmation";
import ThankYou from "./components/pages/thankYou/thankYou";
import Dashboard from "./components/pages/dashboard/dashboard";
import Account from "./components/pages/account/account";
import PurchaseHistory from "./components/pages/purchaseHistory/purchaseHistory";
import About from "./components/pages/about/about";
import Login from "./components/pages/login/login";
import Signup from "./components/pages/signup/signup";
import PromoterSignupForm from "./components/pages/promoter/promoterSignup";
import Successful from "./components/pages/promoter/promoterSuccessful/successful";
import PromoterDashboard from "./components/pages/promoterDashboard/promoterDashboard";
import PromoterLogin from "./components/pages/login/promoterLogin/promoterLogin";
import PromoterPrivateRoute from "./components/privateRoute/promoterPrivateRoute";

import { fetchProducts } from "./components/pages/shop/productHelpers";

import "react-toastify/dist/ReactToastify.min.css";

function App() {
  const location = useLocation();

  // Define an array of routes where the Header component should be hidden
  const hideHeaderRoutes = ["/promoter_dashboard_login", "/promoter_dashboard"];

  // Check if the current route is in the hideHeaderRoutes array
  const shouldHideHeader = hideHeaderRoutes.includes(location.pathname);

  const [appContext, setAppContext] = useContext(AppContext);

  useEffect(() => {
    fetchProducts(appContext, setAppContext);
  }, []);

  return (
    <div className="container">
      {!shouldHideHeader && <Header />}
      <Routes>
        <Route path="/login/:token" element={<Login />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/promoter" element={<PromoterSignupForm />}></Route>
        <Route path="/promoter_successful" element={<Successful />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/payment_details" element={<PaymentDetails />}></Route>
        <Route path="/confirm_order" element={<OrderConfirmation />}></Route>
        <Route path="/thank_you" element={<ThankYou />}></Route>
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/promoter_dashboard/:token"
          element={<PromoterLogin />}
        ></Route>
        <Route
          path="promoter_dashboard_login"
          element={<PromoterLogin />}
        ></Route>
        <Route
          path="/promoter_dashboard"
          element={
            <PromoterPrivateRoute>
              <PromoterDashboard />
            </PromoterPrivateRoute>
          }
        ></Route>
        <Route
          path="/account"
          element={
            <PrivateRoute>
              <Account />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/purchase_history"
          element={
            <PrivateRoute>
              <PurchaseHistory />
            </PrivateRoute>
          }
        ></Route>
        <Route path="/:promoter_code" element={<Shop />}></Route>
        <Route path="/" element={<Shop />}></Route>
        <Route path="*" element={<Navigate replace to="/" />} />
        {/* Example for Private Route */}
        {/* <Route
          path="/"
          element={
            <PrivateRoute>
              <Component />
            </PrivateRoute>
          }
        ></Route> */}
        {/* Example for Private Route */}
      </Routes>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;

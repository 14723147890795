import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../context/appContext";

import { calculateTotalCost } from "../productHelpers";
import { realFormatter } from "../../../../assets/currency/realFormatter";

import styles from "./totalCost.module.scss";

const TotalCost = ({ proceedURL, submitForm = null, submitting = false }) => {
  const [appContext] = useContext(AppContext);
  const [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    if (
      !appContext.products.length ||
      !Object.keys(appContext.selectedProducts).length
    ) {
      setTotalCost(0);
      return;
    }

    const subtotal = calculateTotalCost(appContext.selectedProducts);
    const vat = subtotal * 0.15;
    setTotalCost(subtotal + vat);
  }, [appContext.products, appContext.selectedProducts]);

  return (
    <div className={styles.totalCost}>
      <div className={styles.cost}>
        {realFormatter.format(totalCost)} <span>/ Total Cost</span>{" "}
        <p>
          *Including VAT <strong>SA Only</strong>
        </p>
      </div>
      {!submitForm && (
        <Link to={proceedURL}>
          <button
            className={`primary ${styles.proceed}`}
            disabled={totalCost === 0}
          >
            Proceed
          </button>
        </Link>
      )}
      {submitForm && (
        <span className={styles.submit_btn}>
          <button
            className={`primary ${styles.proceed}`}
            type={"submit"}
            disabled={submitting || totalCost === 0}
          >
            Proceed
          </button>
        </span>
      )}
    </div>
  );
};

export default TotalCost;

import React, { useState, useEffect } from "react";
import { removeCookies } from "../shop/productHelpers";
import { fetchOrderIdInCookies } from "../../../auth/checkLoginCookie";

import Breadcrumbs from "../../global/breadcrumbs/breadcrumbs";
import { base_url, getOrderAgainstOrderId } from "../../../base/api_urls";

import "./thankYou.css";

const ThankYou = () => {
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [orderId, setOrderId] = useState(fetchOrderIdInCookies());

  useEffect(() => {
    const fetchData = async () => {
      const endpoint = `${base_url}${getOrderAgainstOrderId}?order_id=${orderId}`;
      try {
        const response = await fetch(endpoint, {
          method: "GET",
        });
        if (!response.ok) {
          const errorResponse = await response.json();
          throw new Error(
            "Request failed with status " +
              response.status +
              ": " +
              errorResponse.message
          );
        }
        // Process the fetched data as needed
        const data = await response.json();
        setIsFirstTime(data.isFirstTime);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    removeCookies();
  }, []);

  return (
    <section id="thank_you" className="page-gradient-bg bt-thank_you">
      <div className="thank_you_page_container">
        <Breadcrumbs onlyBack={true} />
        {/* icon */}
        <img
          src={require("../../../assets/thankYou/celebrate.png")}
          alt="Celebrate"
          className="celebrate_img"
        />

        <div className="page_headings_container">
          <h1 className="page_heading">Thank you for your order!</h1>
          <br />
          <p className="heading_sub_info red">
            {" "}
            We have sent the following emails to your email address:
          </p>
          <br />
          <div className="heading_sub_info">
            1. The receipt for your order.
            <br />
            {isFirstTime && "2. A login email for your Youth@Work profile."}
            <br />
            <br />
            If you have not received one or several of these emails, please
            check first in your SPAM folder. If you still don’t find the emails
            contact us on
            <a href="mailto:youthPROFILER@sayouthatwork.com" className="red">
              {" "}
              youthPROFILER@sayouthatwork.com
            </a>
            .
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYou;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { fetchCompletedOrders } from "../dashboard/dashboard.service";
import { realFormatter } from "../../../assets/currency/realFormatter";

import Breadcrumbs from "../../global/breadcrumbs/breadcrumbs";

import styles from "./purchaseHistory.module.scss";

const PurchaseHistory = () => {
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (orders.length === 0) fetchCompletedOrders(null, setOrders, false);
  }, [orders]);

  const getProductNames = (products) => {
    let nameString = "";
    products.forEach(({ _product }) => {
      nameString += `${_product.productname}\n\n`;
    });
    return nameString;
  };

  const getProductsQuantity = (products) => {
    let totalQty = "";
    products.forEach(({ qty }) => {
      totalQty += qty;
    });
    return totalQty;
  };

  return (
    <div className={styles.purchase_history}>
      <Breadcrumbs onlyBack={true} backPath={() => navigate(-1)} />
      <h1 className="red">Purchase History</h1>
      <table className={styles.history_table}>
        <thead>
          <tr>
            <th>Order Number</th>
            <th>Purchase Date</th>
            <th>Products</th>
            <th>Quantity</th>
            <th>Total</th>
            <th>Receipt</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => {
            const { id, products, created_at, total, transactionid } = order;
            if (!transactionid) return;
            return (
              <tr key={index}>
                <td data-column="Order Number">YP000{id}</td>
                <td data-column="Purchase Date">
                  {new Date(created_at).toLocaleDateString('en-GB')}
                </td>
                <td data-column="Products">{getProductNames(products)}</td>
                <td data-column="Quantity">{getProductsQuantity(products)}</td>
                <td data-column="Total">{realFormatter.format(total)}</td>
                <td data-column="Receipt">
                  <a
                    href={`https://payfast.com/${transactionid}`}
                    target={"_blank"}
                  >
                    <button className="primary">View</button>
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PurchaseHistory;

import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";

import { AppContext } from "../../../context/appContext";
import { ChildrenContext } from "../../../context/children.context";

import Breadcrumbs from "../../global/breadcrumbs/breadcrumbs";
import Modal from "../../global/modal/modal";
import MainAccountUpdateForm from "./forms/mainAccount.form";
import ChildAccountUpdateForm from "./forms/childAccountForm";
import PromoterForm from "./forms/promoter.form";

import {
  purchase_history_svg,
  plus_sign_svg,
  payment_details_svg,
} from "../../../assets/svgs";

import styles from "./account.module.scss";

const Account = () => {
  const navigate = useNavigate();

  const [appContext] = useContext(AppContext);
  const [childrenContext] = useContext(ChildrenContext);

  const [userData, setUserData] = useState({});
  const [updateAccountModal, set_updateAccountModal] = useState(false);
  const [childAccountModal, set_childAccountModal] = useState(false);
  const [helpAddLicenseModal, set_helpAddLicenseModal] = useState(false);
  const [helpWhyPromoterLinkModal, set_helpWhyPromoterLinkModal] =
    useState(false);
  const [childToUpdate, set_childToUpdate] = useState(null);
  const [promoterCode, set_promoterCode] = useState(":code");
  const [copied, setCopied] = useState(false);
  const [promoterAccountModal, set_promoterAccountModal] = useState(false);
  const [resetForm, set_resetForm] = useState(false);

  const { all_childs } = childrenContext;

  useEffect(() => {
    setUserData(appContext.user_data);
    console.log(appContext);
    if (appContext?.banking_details) {
      if (appContext?.banking_details?.promoter_code) {
        console.log("Promoter Code from DB");
        set_promoterCode(appContext.banking_details.promoter_code);
      } else {
        console.log("Banking ID Promoter Code");
        set_promoterCode(appContext.banking_details.id);
      }
    }
  }, [appContext]);

  const closeModal = (isOpen) => {
    set_childAccountModal(isOpen);
    if (!childToUpdate) {
      set_resetForm(!resetForm);
    }
  };

  return (
    <div className={styles.account}>
      <>
        <Breadcrumbs onlyBack={true} backPath={() => navigate(-1)} />
        <div className={styles.header}>
          <h1 className="red">My Account</h1>
          <Link to={"/purchase_history"}>
            <button className={`primary svg_button`}>
              {purchase_history_svg}
              Purchase History
            </button>
          </Link>
        </div>
        <p className="heading_sub_info">Main Account</p>
        <div className="divider"></div>
        <div className={styles.header}>
          <h2 className="black name">
            {userData?.firstname ?? userData?.first_name}{" "}
            {userData?.lastname ?? userData?.last_name}
          </h2>
          <button className="red" onClick={() => set_updateAccountModal(true)}>
            Update
          </button>
        </div>
        <div
          className="heading_sub_info"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p className="">Linked Child Accounts</p>
          <p
            className=""
            style={{
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => set_helpAddLicenseModal(true)}
          >
            Need help assigning license?
          </p>
        </div>

        <span className="divider"></span>
        <button
          className={`primary svg_button`}
          onClick={() => {
            set_childToUpdate(null);
            set_childAccountModal(true);
          }}
        >
          {plus_sign_svg}
          New Child Account
        </button>
        {all_childs.map((child, index) => {
          return (
            <div key={index} className={styles.header}>
              <h2 className="black name">
                {child.first_name} {child.last_name}
              </h2>
              <div className={styles.two_buttons}>
                {/* <button>Make own account</button> */}
                <button
                  className="red"
                  onClick={() => {
                    set_childToUpdate(child);
                    set_childAccountModal(true);
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          );
        })}
        {promoterCode != ":code" && (
          <div>
            <p className="heading_sub_info" style={{ marginTop: "2vh" }}>
              Promoter Link
            </p>
            <span className="divider"></span>
            <div className={styles.header}>
              <p className="black">
                https://www.app.sayouthatwork.com/{promoterCode}
              </p>
              <div className={styles.two_buttons}>
                <button
                  className="primary svg_button small"
                  onClick={() =>
                    set_helpWhyPromoterLinkModal(!helpWhyPromoterLinkModal)
                  }
                >
                  Help!
                </button>
                <CopyToClipboard
                  text={"https://www.app.sayouthatwork.com/" + promoterCode}
                  onCopy={() => setCopied(true)}
                >
                  <button>{!copied ? "Copy link" : "Copied!"}</button>
                </CopyToClipboard>
                <button
                  className="primary svg_button small"
                  onClick={() =>
                    set_promoterAccountModal(!promoterAccountModal)
                  }
                >
                  {payment_details_svg}
                  Payment Details
                </button>
              </div>
            </div>
          </div>
        )}
      </>

      <Modal
        open={helpWhyPromoterLinkModal}
        closer={set_helpWhyPromoterLinkModal}
        maxWidth={1000}
      >
        <h1 style={{ margin: "30px 0px", textAlign: "center" }}>
          How can I get paid?{" "}
        </h1>
        <p className="help_text">
          Whenever you share your link with friends family or anyone, you will
          earn commission and get paid! YES, its our way of saying thank you 🧡.
          You can now earn money by sharing your link 😎. Simply, copy your link
          and start sharing.{" "}
        </p>
      </Modal>

      <Modal
        open={updateAccountModal}
        closer={set_updateAccountModal}
        maxWidth={1000}
      >
        <MainAccountUpdateForm
          set_updateAccountModal={set_updateAccountModal}
        />
      </Modal>

      <Modal open={childAccountModal} closer={closeModal} maxWidth={1000}>
        <ChildAccountUpdateForm
          child={childToUpdate}
          resetForm={resetForm}
          set_resetForm={set_resetForm}
          set_helpAddLicenseModal={set_helpAddLicenseModal}
          set_childAccountModal={set_childAccountModal}
        />
      </Modal>

      <Modal
        open={promoterAccountModal}
        closer={set_promoterAccountModal}
        maxWidth={1000}
      >
        <PromoterForm
          updatePaymentOnly={true}
          set_promoterAccountModal={set_promoterAccountModal}
        />
      </Modal>
      <Modal
        open={helpAddLicenseModal}
        closer={set_helpAddLicenseModal}
        maxWidth={1000}
      >
        <img
          src={require("../../../assets/account/assignLicense.gif")}
          style={{ width: "inherit", marginTop: "10px" }}
        />
      </Modal>
    </div>
  );
};

export default Account;

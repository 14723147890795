import React from "react";
import { Link } from "react-router-dom";

import styles from "./breadcrumbs.module.scss";

const nav_items = [
  { title: "Select Product", url: "/" },
  { title: "Payment Details", url: "/payment_details" },
  { title: "Order Confirmation", url: "/confirm_order" },
];

const Breadcrumbs = ({ active = 1, onlyBack = false, backPath = null }) => {
  return (
    <div className={styles.breadcrumbs}>
      {active > 0 && (
        <Link
          to={nav_items[active - 1].url}
          className={styles.go_back}
          onClick={(e) => {
            if (backPath) {
              e.preventDefault();
              backPath();
            }
          }}
        >
          <svg
            width="13"
            height="20"
            viewBox="0 0 13 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11 1L3 10L11 19" stroke="#9E9D9C" strokeWidth="3" />
          </svg>
          Go Back
        </Link>
      )}
      {!onlyBack &&
        nav_items.map(({ title, url }, index) => {
          return (
            <Link
              key={index}
              to={url}
              className={active === index ? styles.active : ""}
            >
              {title}
            </Link>
          );
        })}
    </div>
  );
};

export default Breadcrumbs;

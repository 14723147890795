import React, { useState, createContext } from "react";

export const ChildrenContext = createContext();

export const ChildrenContextProvider = ({ children }) => {
  const [childrenContext, setChildrenContext] = useState({
    all_childs: [],
    linked_children: {},
    selected_child: 0,
  });

  return (
    <ChildrenContext.Provider value={[childrenContext, setChildrenContext]}>
      {children}
    </ChildrenContext.Provider>
  );
};

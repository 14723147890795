import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../../context/appContext";
import { realFormatter } from "../../../../assets/currency/realFormatter";
import { updateSelectedProducts } from "../productHelpers";

import Modal from "../../../global/modal/modal";
import Counter from "./components/counter";
import FullDescription from "./components/fullDescription";

const ProductBox = ({ styles, product }) => {
  const [appContext, setAppContext] = useContext(AppContext);
  const [quantity, setQuantity] = useState(0);
  const [openDescription, setOpenDescription] = useState(false);

  const {
    id,
    productname,
    price,
    description,
    // isactive,
    pid,
    password,
    product_image,
  } = product;

  const formattedPrice = realFormatter.format(price);

  const updateQuantityInContext = (quantity) => {
    updateSelectedProducts(
      id,
      pid,
      password,
      productname,
      price,
      quantity,
      appContext,
      setAppContext
    );
  };

  const changeQuantityManually = (e) => {
    const enteredValue = e.target.value;
    if (!Number(enteredValue)) {
      setQuantity(0);
      return;
    }

    if (enteredValue < 0) return;

    setQuantity(parseInt(enteredValue));
  };

  const updateQuantitywithButton = (add = true) => {
    if (add) {
      setQuantity(quantity + 1);
    } else {
      if (quantity - 1 > -1) {
        setQuantity(quantity - 1);
      }
    }
  };

  useEffect(() => {
    const matchingProduct = appContext.selectedProducts[id];
    if (!matchingProduct) return;

    setQuantity(matchingProduct.quantity);
  }, [appContext.selectedProducts]);

  useEffect(() => {
    updateQuantityInContext(quantity);
  }, [quantity]);

  return (
    <div className={styles.product}>
      <div className={styles.inner}>
        <div className={styles.thumbnail}>
          {" "}
          <img src={product_image.url} alt={productname} />
        </div>
        <div className={styles.details}>
          <div className={styles.top}>
            <p className={styles.title}>{productname}</p>
            <p className={styles.price}>{formattedPrice}</p>
          </div>
          <p className={styles.description}>{description}</p>
          <button
            className={`pill red ${styles.full_desc_btn}`}
            onClick={() => setOpenDescription(true)}
          >
            Read More
          </button>

          <Counter
            updateQuantitywithButton={updateQuantitywithButton}
            quantity={quantity}
            changeQuantityManually={changeQuantityManually}
            styles={styles}
          />
        </div>
      </div>

      <Modal open={openDescription} closer={setOpenDescription} maxWidth={900}>
        <FullDescription
          price={price}
          updateQuantitywithButton={updateQuantitywithButton}
          quantity={quantity}
          changeQuantityManually={changeQuantityManually}
          styles={styles}
        />
      </Modal>
    </div>
  );
};

export default ProductBox;

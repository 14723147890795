import {
  base_url,
  requestMagicLinkEndpointPromoter,
} from "../../../../base/api_urls";

export const requestMagicLinkPromoter = (email, setLinkSent) => {
  fetch(base_url + requestMagicLinkEndpointPromoter + `?email=${email}`)
    .then((response) => response.json())
    .then((data) => {
      if (data) setLinkSent();
    });
};

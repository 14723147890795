import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../../../context/appContext";

import {
  fetchActiveProductsLicenses,
  fetchChildProducts,
} from "./dashboard.service";

import Assessment from "./assesment/assesment";
import Modal from "../../global/modal/modal";

import styles from "./dashboard.module.scss";

const Dashboard = () => {
  const [appContext] = useContext(AppContext);
  const [licenseExpiredModal, set_licenseExpiredModal] = useState(false);
  const [tooSoonModal, set_tooSoonModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [assessments, setAssessments] = useState([]);

  const { logged_in_data } = appContext;

  const fetchFreshData = () => {
    if (!logged_in_data.child)
      fetchActiveProductsLicenses(setProducts, setAssessments);
    else fetchChildProducts(setProducts, setAssessments);
  };

  useEffect(() => {
    if (!products.length) {
      if (!logged_in_data) return;
      fetchFreshData();
    }
  }, [logged_in_data]);

  return (
    <div className={styles.dashboard}>
      <div className={styles.assesments}>
        {products.length === 0 && <h1>No Assessments Available</h1>}
        {products &&
          products.map((product) => {
            const { products_id, product_id, licenses, _product } = product;
            return (
              <Assessment
                key={products_id ?? product_id}
                id={products_id ?? product_id}
                qty={licenses}
                product={_product}
                styles={styles}
                openExpireModal={set_licenseExpiredModal}
                openSoonModal={set_tooSoonModal}
                child={logged_in_data?.child}
                assessments={assessments}
                fetchFreshData={() => fetchFreshData()}
              />
            );
          })}
      </div>

      {/* Too soon modal */}
      <Modal open={tooSoonModal} closer={set_tooSoonModal} maxWidth={450}>
        <>
          <h1 style={{ textAlign: "center" }}>To soon to retest</h1>
          <p className="heading_sub_info" style={{ textAlign: "center" }}>
            Please try again after 40 days.
          </p>
        </>
      </Modal>

      {/* License Expired modal */}
      <Modal open={licenseExpiredModal} closer={set_licenseExpiredModal}>
        <>
          <h1 style={{ textAlign: "center" }}>License Expired!</h1>
          <p className="heading_sub_info" style={{ textAlign: "center" }}>
            Please visit the Shop and re-purchase a license.
          </p>
          <div className={styles.license_expired_btn}>
            <button
              className="primary light"
              onClick={() => set_licenseExpiredModal(false)}
            >
              Later
            </button>
            <Link to={"/"}>
              <button className="primary">Shop Now</button>
            </Link>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default Dashboard;

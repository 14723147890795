import React, { useState, createContext } from "react";

export const OrdersContext = createContext();

export const OrdersContextProvider = ({ children }) => {
  const [ordersContext, setOrdersContext] = useState({
    completed_orders: [],
  });

  return (
    <OrdersContext.Provider value={[ordersContext, setOrdersContext]}>
      {children}
    </OrdersContext.Provider>
  );
};

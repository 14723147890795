export const forward_svg = (
  <svg viewBox="0 0 20 20">
    <path d="M12.522,10.4l-3.559,3.562c-0.172,0.173-0.451,0.176-0.625,0c-0.173-0.173-0.173-0.451,0-0.624l3.248-3.25L8.161,6.662c-0.173-0.173-0.173-0.452,0-0.624c0.172-0.175,0.451-0.175,0.624,0l3.738,3.736C12.695,9.947,12.695,10.228,12.522,10.4 M18.406,10c0,4.644-3.764,8.406-8.406,8.406c-4.644,0-8.406-3.763-8.406-8.406S5.356,1.594,10,1.594C14.643,1.594,18.406,5.356,18.406,10M17.521,10c0-4.148-3.374-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.147,17.521,17.521,14.147,17.521,10"></path>
  </svg>
);

export const purchase_history_svg = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.23924 15.1961C5.11548 14.5773 5.58878 14 6.21982 14H21.7802C22.4113 14 22.8846 14.5773 22.7608 15.1961L21.4823 21.5883C21.2019 22.9906 19.9706 24 18.5406 24H9.45943C8.02939 24 6.79814 22.9906 6.51769 21.5883L5.23924 15.1961ZM15 17C15 16.4477 15.4478 16 16 16C16.5523 16 17 16.4477 17 17V21C17 21.5523 16.5523 22 16 22C15.4478 22 15 21.5523 15 21V17ZM12 16C11.4478 16 11 16.4477 11 17V21C11 21.5523 11.4478 22 12 22C12.5523 22 13 21.5523 13 21V17C13 16.4477 12.5523 16 12 16Z"
      fill="white"
    />
    <path
      d="M4 11C4 10.4477 4.44772 10 5 10H23C23.5523 10 24 10.4477 24 11C24 11.5523 23.5523 12 23 12H5C4.44772 12 4 11.5523 4 11Z"
      fill="white"
    />
    <path
      d="M12.7071 5.70711C13.0976 5.31658 13.0976 4.68342 12.7071 4.29289C12.3166 3.90237 11.6834 3.90237 11.2929 4.29289L9.29289 6.29289C8.90237 6.68342 8.90237 7.31658 9.29289 7.70711C9.68342 8.09763 10.3166 8.09763 10.7071 7.70711L12.7071 5.70711Z"
      fill="white"
    />
    <path
      d="M15.2929 5.70711C14.9024 5.31658 14.9024 4.68342 15.2929 4.29289C15.6834 3.90237 16.3166 3.90237 16.7071 4.29289L18.7071 6.29289C19.0976 6.68342 19.0976 7.31658 18.7071 7.70711C18.3166 8.09763 17.6834 8.09763 17.2929 7.70711L15.2929 5.70711Z"
      fill="white"
    />
  </svg>
);

export const plus_sign_svg = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6409_22)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.0410172 13.7796C-0.0410174 12.4058 1.36459 12.4425 2.90575 12.4425L12.9586 12.4425L12.9586 3.4807C12.9583 1.74573 12.9175 0.854319 14.4586 0.85432C15.9998 0.85432 15.9586 1.74572 15.9586 3.4807L15.9586 12.4425L26.5832 12.4425C28.1244 12.4425 29.4582 12.4058 29.4582 13.7796C29.4582 15.1534 28.1244 15.1167 26.5832 15.1167L15.9586 15.1167L15.9586 24.5875C15.9586 25.9613 15.9994 27.1505 14.4582 27.1505C12.9171 27.1505 12.9586 25.9613 12.9586 24.5875L12.9586 15.1167L2.90575 15.1167C1.36459 15.1167 -0.0410174 15.1534 -0.0410172 13.7796Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_6409_22">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const payment_details_svg = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 7H7C5.89543 7 5 7.89543 5 9V19C5 20.1046 5.89543 21 7 21H21C22.1046 21 23 20.1046 23 19V9C23 7.89543 22.1046 7 21 7ZM7 5C4.79086 5 3 6.79086 3 9V19C3 21.2091 4.79086 23 7 23H21C23.2091 23 25 21.2091 25 19V9C25 6.79086 23.2091 5 21 5H7Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 14C15 11.2386 17.2386 9 20 9H24C24.5523 9 25 9.44772 25 10C25 10.5523 24.5523 11 24 11H20C18.3431 11 17 12.3431 17 14C17 15.6569 18.3431 17 20 17H24C24.5523 17 25 17.4477 25 18C25 18.5523 24.5523 19 24 19H20C17.2386 19 15 16.7614 15 14Z"
      fill="white"
    />
    <path
      d="M21 14C21 14.5523 20.5523 15 20 15C19.4477 15 19 14.5523 19 14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14Z"
      fill="white"
    />
  </svg>
);

export const eye_svg = (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
  </svg>
);

export const hide_eye_svg = (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
  >
    <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" />
  </svg>
);

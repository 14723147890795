import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../../../context/appContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import classNames from "classnames";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import countriesList from "../../../assets/data/countriesList.json";
import {
  PaymentDetailsSchemaPersonal,
  PaymentDetailsSchemaPersonalInitialValues,
  PaymentDetailsSchemaBusiness,
  PaymentDetailsSchemaBusinessInitialValues,
} from "./paymentDetails.schema";
import { base_url, paymentDetailsEndpoint } from "../../../base/api_urls";
import { readExistingSelectedProducts } from "../shop/productHelpers";
import {
  fetchUserDataInCookies,
  saveUserDataInCookies,
} from "../../../auth/checkLoginCookie";

import InputField from "../../form/inputField/inputField";

import Breadcrumbs from "../../global/breadcrumbs/breadcrumbs";
import TotalCost from "../shop/totalCost/totalCost";

const PaymentDetails = () => {
  const navigate = useNavigate();

  const [appContext, setAppContext] = useContext(AppContext);
  const [countryCallingCodes, setCountryCallingCodes] = useState([]);
  const [personalChecked, setPersonalChecked] = useState(true);
  const [businessChecked, setBusinessChecked] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const initialValues = {
    personal: PaymentDetailsSchemaPersonalInitialValues,
    business: PaymentDetailsSchemaBusinessInitialValues,
  };

  const existingUserData = fetchUserDataInCookies();
  if (existingUserData) {
    Object.keys(existingUserData).forEach((key) => {
      initialValues.personal[key] = existingUserData[key];
      initialValues.business[key] = existingUserData[key];
    });
  }

  useEffect(() => {
    const selectedProducts =
      appContext?.selectedProducts ?? readExistingSelectedProducts();
    if (!selectedProducts || Object.keys(selectedProducts).length === 0) {
      navigate("/");
      toast.error("You must select at least 1 product to continue");
      return;
    }
  }, [appContext]);

  useEffect(() => {
    if (!countryCallingCodes.length) {
      const countriesCodeList = getCountries();
      let callingCodesList = [];
      for (const countryCode of countriesCodeList) {
        const callingCode = getCountryCallingCode(countryCode);
        if (callingCodesList.indexOf(callingCode) === -1) {
          callingCodesList.push(callingCode);
        }
      }
      callingCodesList.sort((a, b) => a - b);
      setCountryCallingCodes(callingCodesList);
    }
  }, [countryCallingCodes]);

  const submitForm = async (values) => {
    console.log(values);
    values["accounttype"] = personalChecked ? "personal" : "business";

    setFormSubmitting(true);

    fetch(base_url + paymentDetailsEndpoint, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        setFormSubmitting(false);
        if (data?.code || data?.message || !data) {
          setFormSubmitting(false);
          return;
        }
        const { orderid, userid } = data;
        const user_data = { ...values, orderid, userid };
        setAppContext({ ...appContext, user_data });
        saveUserDataInCookies(user_data);
        navigate("/confirm_order");
      })
      .catch((error) => {
        console.error(error);
        setFormSubmitting(false);
      });
  };

  return (
    <>
      <Breadcrumbs active={1} />
      <h1>Payment Details</h1>
      <p className={"heading_sub_info"}>Please enter your contact details</p>
      <div className="form_row one-column">
        <div className="checkbox-block">
          <input
            type="checkbox"
            id="personal"
            name="toggle2"
            checked={personalChecked}
            onChange={(e) => {
              setBusinessChecked(false);
              setPersonalChecked(e.target.checked);
            }}
          />
          <label htmlFor="personal">{"Personal"}</label>
        </div>
        <div className="checkbox-block">
          <input
            type="checkbox"
            id="business"
            name="toggle"
            checked={businessChecked}
            onChange={(e) => {
              setPersonalChecked(false);
              setBusinessChecked(e.target.checked);
            }}
          />
          <label htmlFor="business">{"Business"}</label>
        </div>
      </div>
      {/* Personal Form */}
      {personalChecked && (
        <Formik
          initialValues={initialValues.personal}
          onSubmit={submitForm}
          validationSchema={PaymentDetailsSchemaPersonal}
        >
          {({ values, errors, touched, setFieldValue, handleChange }) => (
            <Form id="salesContact">
              <div className="form_row">
                <div className="form_field">
                  <InputField
                    name="firstname"
                    placeholder={"First Name"}
                    label={"First Name"}
                    form={{ errors, touched }}
                  />
                </div>
                <div className="form_field ">
                  <InputField
                    name="lastname"
                    placeholder={"Last Name"}
                    label={"Last Name"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>
              <div className="form_row">
                <div className="form_field ">
                  <InputField
                    name="email"
                    type="email"
                    placeholder={"Email"}
                    label={"Email"}
                    form={{ errors, touched }}
                  />
                </div>
                <div className="form_field phone_field_container">
                  <div className="phone_country_code">
                    <label htmlFor={"code"}>Code</label>
                    <Field
                      as="select"
                      className="input_field"
                      name="countryCallingCode"
                    >
                      {countryCallingCodes.map((countryCode) => (
                        <option key={countryCode} value={countryCode}>
                          +{countryCode}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="form_field full_input">
                    <InputField
                      name="phone"
                      placeholder={"Tel/Mob"}
                      label={"Tel/Mob"}
                      form={{ errors, touched }}
                    />
                  </div>
                </div>
              </div>
              <div className="form_row">
                <div className="form_field ">
                  <InputField
                    name="address"
                    placeholder={"Street"}
                    label={"Street"}
                    form={{ errors, touched }}
                  />
                </div>
                <div className="form_field">
                  <InputField
                    name="houseno"
                    placeholder={"House Number"}
                    label={"House Number"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>
              <div className="form_row">
                <div className="form_field ">
                  <InputField
                    name="postcode"
                    placeholder={"Post Code"}
                    label={"Post Code"}
                    form={{ errors, touched }}
                  />
                </div>
                <div className="form_field">
                  <InputField
                    name="town"
                    placeholder={"Town"}
                    label={"Town"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>

              <div className="form_row">
                <div className="form_field">
                  <label htmlFor={"country"}>Country</label>
                  <Field
                    as="select"
                    name="country"
                    className={classNames(
                      "input_field",
                      errors.country && touched.country ? "input_error" : null
                    )}
                    onChange={(e) => {
                      setFieldValue(
                        "countryCallingCode",
                        getCountryCallingCode(e.target.value || "CH")
                      );
                      handleChange(e);
                    }}
                  >
                    <option value="" disabled>
                      {"Country"}
                    </option>
                    {countriesList.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    component="span"
                    name="country"
                    className="error"
                  />
                </div>

                <div className="form_field">
                  <TotalCost submitForm={true} submitting={formSubmitting} />
                </div>
              </div>

              <div className="form_row">
                <div className="form_field ">
                  <InputField
                    name="coupon"
                    type="text"
                    placeholder={"Coupon"}
                    label={"Coupon"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {/* Personal Form */}

      {/* Business Form */}
      {businessChecked && (
        <Formik
          initialValues={initialValues.business}
          onSubmit={submitForm}
          validationSchema={PaymentDetailsSchemaBusiness}
        >
          {({ values, errors, touched, setFieldValue, handleChange }) => (
            <Form id="salesContact">
              <div className="form_row">
                <div className="form_field">
                  <InputField
                    name="orgname"
                    placeholder={"Organisation"}
                    label={"Organisation"}
                    form={{ errors, touched }}
                  />
                </div>
                <div className="form_field">
                  <InputField
                    name="firstname"
                    placeholder={"First Name"}
                    label={"First Name"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>
              <div className="form_row">
                <div className="form_field">
                  <label htmlFor={"country"}>Country</label>
                  <Field
                    as="select"
                    name="country"
                    className={classNames(
                      "input_field",
                      errors.country && touched.country ? "input_error" : null
                    )}
                    onChange={(e) => {
                      setFieldValue(
                        "countryCallingCode",
                        getCountryCallingCode(e.target.value || "CH")
                      );
                      handleChange(e);
                    }}
                  >
                    <option value="" disabled>
                      {"Country"}
                    </option>
                    {countriesList.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    component="span"
                    name="country"
                    className="error"
                  />
                </div>
                <div className="form_field ">
                  <InputField
                    name="lastname"
                    placeholder={"Last Name"}
                    label={"Last Name"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>

              <div className="form_row">
                <div className="form_field ">
                  <InputField
                    name="address"
                    placeholder={"Address"}
                    label={"Address"}
                    form={{ errors, touched }}
                  />
                </div>
                <div className="form_field">
                  <InputField
                    name="houseno"
                    placeholder={"House Number"}
                    label={"House Number"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>
              <div className="form_row">
                <div className="form_field ">
                  <InputField
                    name="postcode"
                    placeholder={"Post Code"}
                    label={"Post Code"}
                    form={{ errors, touched }}
                  />
                </div>
                <div className="form_field">
                  <InputField
                    name="town"
                    placeholder={"Town"}
                    label={"Town"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>
              <div className="form_row">
                <div className="form_field phone_field_container">
                  <div className="phone_country_code">
                    <label htmlFor={"code"}>Code</label>
                    <Field
                      as="select"
                      className="input_field"
                      name="countryCallingCode"
                    >
                      {countryCallingCodes.map((countryCode) => (
                        <option key={countryCode} value={countryCode}>
                          +{countryCode}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="form_field full_input">
                    <InputField
                      name="phone"
                      placeholder={"Tel/Mob"}
                      label={"Tel/Mob"}
                      form={{ errors, touched }}
                    />
                  </div>
                </div>
                <div className="form_field ">
                  <InputField
                    name="email"
                    type="email"
                    placeholder={"Email"}
                    label={"Email"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>

              <div className="form_row">
                <div className="form_field ">
                  <InputField
                    name="coupon"
                    type="text"
                    placeholder={"Coupon"}
                    label={"Coupon"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>

              <div className="form_row right">
                <div className="form_field">
                  <TotalCost submitForm={true} submitting={formSubmitting} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {/* Business Form */}
    </>
  );
};

export default PaymentDetails;

import {
  base_url,
  getAllChildrenEndpoint,
  getAllCompletedOrdersEndpoint,
  getAllProductsActiveLicenses,
  linkChildToProductEndpoint,
  removLinkChildToProductEndpoint,
  getAllParentChildLinksEndpoint,
  fetchChildProductsAndAssessmentsURL,
  getOrderAgainstOrderId,
} from "../../../base/api_urls";
import { checkLoginCookie } from "../../../auth/checkLoginCookie";
import { toast } from "react-toastify";

export const getOrderData = async (orderId) => {
  const endpoint = `${base_url}${getOrderAgainstOrderId}?order_id=${orderId}`;
  try {
    const response = await fetch(endpoint, {
      method: "GET",
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        "Request failed with status " +
          response.status +
          ": " +
          errorResponse.message
      );
    }
    // Process the fetched data as needed
    const data = await response.json();
    return data.isFirstTime;
  } catch (error) {
    console.error("Error fetching data:", error.message);
  }
};

export const getAllChildrenOfAUser = (childrenContext, setChildrenContext) => {
  const authToken = checkLoginCookie();
  if (!authToken) return;

  fetch(base_url + getAllChildrenEndpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setChildrenContext({ ...childrenContext, all_childs: data });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const parseCompletedOrdersInProductsArray = (orders) => {
  const temp_products = {};
  orders.forEach((order) => {
    order.products.forEach((single_order_products) => {
      const { id, qty, _product } = single_order_products;
      if (!temp_products[id]) temp_products[id] = { id, qty, _product };
      else
        temp_products[id] = {
          ...temp_products[id],
          qty: temp_products[id].qty + qty,
        };
    });
  });
  return temp_products;
};

export const fetchCompletedOrders = (
  ordersContext,
  setOrdersContext,
  parse = true
) => {
  const authToken = checkLoginCookie();
  if (!authToken) return;

  fetch(base_url + getAllCompletedOrdersEndpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data || data?.code || data?.message) return;
      if (!parse) {
        return setOrdersContext(data);
      } else {
        parseCompletedOrdersInProductsArray(data);
        setOrdersContext({
          ...ordersContext,
          completed_orders: parseCompletedOrdersInProductsArray(data),
        });
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const fetchActiveProductsLicenses = (setProducts, setAssessments) => {
  const authToken = checkLoginCookie();
  if (!authToken) return;

  fetch(base_url + getAllProductsActiveLicenses, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data || data?.code || data?.message) return;
      setProducts(data.products);
      setAssessments(data.assessments);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const fetchChildProducts = (setProducts, setAssessments) => {
  const authToken = checkLoginCookie();
  if (!authToken) return;

  fetch(base_url + fetchChildProductsAndAssessmentsURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data || data?.code || data?.message) return;
      setProducts(data.products);
      setAssessments(data.assessments);
    })
    .catch((error) => {
      toast.error("Error while getting link");
      console.error(error);
    });
};

export const linkChildToProduct = async (product_id, child_ids) => {
  const authToken = checkLoginCookie();
  if (!authToken) return;

  return new Promise((resolve, reject) => {
    fetch(base_url + linkChildToProductEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ product_id, child_ids }),
    })
      .then((response) => response.json())
      .then(() => {
        const loading_toast = toast.loading("Linking child to product ...", {
          autoClose: false,
        });
        resolve(loading_toast);
      })
      .catch((error) => {
        toast.error("Error while linking child to product");
        reject(error);
      });
  });
};

export const removeLinkChildToProduct = async (product_id, child_id) => {
  const authToken = checkLoginCookie();
  if (!authToken) return;

  return new Promise((resolve, reject) => {
    fetch(base_url + removLinkChildToProductEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ product_id, child_id }),
    })
      .then((response) => response.json())
      .then(() => {
        toast.success("Un-Linked successfully");
        resolve();
      })
      .catch((error) => {
        toast.error("Error while un-linking child");
        console.error(error);
        reject(error);
      });
  });
};

export const getAllParentChildLinks = (id, set_linked_children) => {
  const authToken = checkLoginCookie();
  if (!authToken) return;

  fetch(base_url + getAllParentChildLinksEndpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data || data?.code || data?.message) return;
      let linkedArray = [];
      data.forEach(({ product_id, child_first_name, child_id }) => {
        if (id == product_id) {
          linkedArray.push({
            product_id,
            first_name: child_first_name,
            child_id,
          });
        }
      });
      set_linked_children(linkedArray);
    })
    .catch((error) => {
      console.error(error);
    });
};

import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../context/appContext";
import Cookies from "js-cookie";

import Modal from "../../global/modal/modal";
import MainAccountUpdateForm from "../account/forms/mainAccount.form";
import ChildAccountUpdateForm from "../account/forms/childAccountForm";
import PromoterForm from "../account/forms/promoter.form";
import { getOrderData } from "./promoterDashboard.service";

import styles from "./promoterDashboard.module.scss";
import PromoterHeader from "../../global/header/promoterHeader/promoterHeader";
import { useNavigate } from "react-router-dom";

const PromoterDashboard = () => {
  const navigate = useNavigate();
  const [appContext] = useContext(AppContext);

  const [userData, setUserData] = useState({});
  const [updateAccountModal, set_updateAccountModal] = useState(false);
  const [childAccountModal, set_childAccountModal] = useState(false);
  const [helpAddLicenseModal, set_helpAddLicenseModal] = useState(false);
  const [helpWhyPromoterLinkModal, set_helpWhyPromoterLinkModal] =
    useState(false);
  const [childToUpdate, set_childToUpdate] = useState(null);
  const [promoterCode, set_promoterCode] = useState(":code");
  const [promoterAccountModal, set_promoterAccountModal] = useState(false);
  const [resetForm, set_resetForm] = useState(false);
  const [orderData, setOrderData] = useState([]);

  if (!Cookies.get("promoterAuthToken")) {
    navigate("/promoter_dashboard_login");
  }

  useEffect(() => {
    setUserData(appContext.user_data);
    let promoter_code;
    if (appContext?.banking_details) {
      if (appContext?.banking_details?.promoter_code) {
        promoter_code = appContext.banking_details.promoter_code;
        set_promoterCode(appContext.banking_details.promoter_code);
      } else {
        promoter_code = appContext.banking_details.id;
        set_promoterCode(appContext.banking_details.id);
      }
    }
    if (promoter_code) {
      getOrderData(promoter_code)
        .then((tempData) => {
          setOrderData(tempData);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [appContext]);

  const closeModal = (isOpen) => {
    set_childAccountModal(isOpen);
    if (!childToUpdate) {
      set_resetForm(!resetForm);
    }
  };

  return (
    <PromoterHeader>
      <div className={styles.account}>
        <>
          <div className={styles.header}>
            <h1 className="red">Dashboard</h1>
          </div>
          <div className={styles.header}>
            <strong>
              <p className="black name">
                {userData?.firstname ?? userData?.first_name}{" "}
                {userData?.lastname ?? userData?.last_name}
              </p>
            </strong>
          </div>
          <strong>
            <p className="heading_sub_info">Referrals</p>
          </strong>
          <div className="divider"></div>
          <table className={styles.history_table} style={{ margin: 0 }}>
            <tr>
              <th>First name</th>
              <th>Last name</th>
              <th>Email</th>
              <th>Order amount</th>
              <th>Order date</th>
            </tr>
            <tbody>
              {orderData.map((item) => (
                <tr key={item.id}>
                  <td>{item.user_data.firstname}</td>
                  <td>{item.user_data.lastname}</td>
                  <td>{item.user_data.email}</td>
                  <td>{item.total}R</td>
                  <td>
                    {item.created_at
                      ? new Date(item.created_at).toLocaleDateString()
                      : ""}
                  </td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </>

        <Modal
          open={helpWhyPromoterLinkModal}
          closer={set_helpWhyPromoterLinkModal}
          maxWidth={1000}
        >
          <h1 style={{ margin: "30px 0px", textAlign: "center" }}>
            How can I get paid?{" "}
          </h1>
          <p className="help_text">
            Whenever you share your link with friends family or anyone, you will
            earn commission and get paid! YES, its our way of saying thank you
            🧡. You can now earn money by sharing your link 😎. Simply, copy
            your link and start sharing.{" "}
          </p>
        </Modal>

        <Modal
          open={updateAccountModal}
          closer={set_updateAccountModal}
          maxWidth={1000}
        >
          <MainAccountUpdateForm
            set_updateAccountModal={set_updateAccountModal}
          />
        </Modal>

        <Modal open={childAccountModal} closer={closeModal} maxWidth={1000}>
          <ChildAccountUpdateForm
            child={childToUpdate}
            resetForm={resetForm}
            set_resetForm={set_resetForm}
            set_helpAddLicenseModal={set_helpAddLicenseModal}
            set_childAccountModal={set_childAccountModal}
          />
        </Modal>

        <Modal
          open={promoterAccountModal}
          closer={set_promoterAccountModal}
          maxWidth={1000}
        >
          <PromoterForm
            updatePaymentOnly={true}
            set_promoterAccountModal={set_promoterAccountModal}
          />
        </Modal>

        <Modal
          open={helpAddLicenseModal}
          closer={set_helpAddLicenseModal}
          maxWidth={1000}
        >
          <img
            src={require("../../../assets/account/assignLicense.gif")}
            style={{ width: "inherit", marginTop: "10px" }}
          />
        </Modal>
      </div>
    </PromoterHeader>
  );
};

export default PromoterDashboard;

import * as yup from "yup";

import { checkIfChildExist, checkIfPromoterExist } from "../account.service";

export const MainAccountSchema = yup.object().shape({
  firstname: yup.string().required("This field is required."),
  lastname: yup.string().required("This field is required."),
  email: yup
    .string()
    .required("This field is required.")
    .matches(
      /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/,
      "Enter valid Email."
    ),
  phone: yup.string().required("This field is required."),
  countryCallingCode: yup.string().required("This field is required."),
  address: yup.string().required("This field is required."),
  houseno: yup.string().required("This field is required."),
  postcode: yup.string().required("This field is required."),
  town: yup.string().required("This field is required."),
  country: yup.string().length(2).required("This field is required."),
});

export const MainAccountInitialValues = {
  firstname: "",
  lastname: "",
  email: "",
  countryCallingCode: "",
  phone: "",
  address: "",
  houseno: "",
  postcode: "",
  town: "",
  country: "",
};

export const ChildAccountSchema = yup.object().shape({
  firstname: yup.string().required("This field is required."),
  lastname: yup.string().required("This field is required."),
  child_id: yup
    .string()
    .required("This field is required.")
    .test(
      "check-child-id-exists",
      "Child ID already exists",
      async function (child_id) {
        if (child_id?.length === 13) {
          const childExists = await checkIfChildExist(child_id);
          if (childExists) {
            throw new yup.ValidationError(
              "Child ID already exists",
              null,
              "child_id"
            );
          }
        }
        return true;
      }
    )
    .matches(/^[0-9]{13}$/, "Child ID Number must be 13 numbers"), // ID Number must be 13 numbers.
  child_pw: yup
    .string()
    .required("You must enter a password for the child account.")
    .test(
      "password-requirements",
      "Password must meet the following requirements:",
      function (value) {
        if (value) {
          let errors = [];
          if (!/(?=.*[a-z])/.test(value)) {
            errors.push("At least one lowercase letter is required.");
          }
          if (!/(?=.*[A-Z])/.test(value)) {
            errors.push("At least one uppercase letter is required.");
          }
          if (!/(?=.*[0-9])/.test(value)) {
            errors.push("At least one number is required.");
          }
          if (/[^a-zA-Z0-9]/.test(value)) {
            errors.push("Special characters are not allowed.");
          }
          if (value.length < 7) {
            errors.push("Password must be at least 7 characters long.");
          }
          if (errors.length > 0) {
            throw new yup.ValidationError(errors.join(" "), null, "child_pw");
          }
        }
        return true;
      }
    ),
});

export const ChildAccountUpdateSchema = yup.object().shape({
  firstname: yup.string().required("This field is required."),
  lastname: yup.string().required("This field is required."),
  child_id: yup.string().required("This field is required."),
  child_pw: yup
    .string()
    .required("You must enter a password for the child account.")
    .test(
      "password-requirements",
      "Password must meet the following requirements:",
      function (value) {
        if (value) {
          let errors = [];
          if (!/(?=.*[a-z])/.test(value)) {
            errors.push("At least one lowercase letter is required.");
          }
          if (!/(?=.*[A-Z])/.test(value)) {
            errors.push("At least one uppercase letter is required.");
          }
          if (!/(?=.*[0-9])/.test(value)) {
            errors.push("At least one number is required.");
          }
          if (/[^a-zA-Z0-9]/.test(value)) {
            errors.push("Special characters are not allowed.");
          }
          if (value.length < 7) {
            errors.push("Password must be at least 7 characters long.");
          }
          if (errors.length > 0) {
            throw new yup.ValidationError(errors.join(" "), null, "child_pw");
          }
        }
        return true;
      }
    ),
});

export const ChildAccountInitialValues = {
  firstname: "",
  lastname: "",
  child_id: "",
  child_pw: "",
};

export const PromoterAccountSchema = yup.object().shape({
  firstname: yup.string().required("This field is required."),
  lastname: yup.string().required("This field is required."),
  email: yup
    .string()
    .required("This field is required.")
    .matches(
      /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/,
      "Enter valid Email."
    ),
  phone: yup.string().required("This field is required."),
  countryCallingCode: yup.string().required("This field is required."),
  address: yup.string().required("This field is required."),
  houseno: yup.string().required("This field is required."),
  postcode: yup.string().required("This field is required."),
  town: yup.string().required("This field is required."),
  country: yup.string().length(2).required("This field is required."),
  id_number: yup
    .string()
    .required("This field is required.")
    .test(
      "check-id-number-exists",
      "ID Number already exists",
      async function (id_number) {
        if (id_number?.length === 13) {
          const promoterExists = await checkIfPromoterExist(id_number);
          if (promoterExists) {
            throw new yup.ValidationError(
              "ID Number already exists",
              null,
              "id_number"
            );
          }
        }
        return true;
      }
    )
    .matches(/^[0-9]{13}$/, "ID Number must be 13 numbers"),
  account_type: yup
    .string()
    .oneOf(["Cheque/Current", "Savings", "Other"])
    .required("This field is required."),
  bank_name: yup.string().required("This field is required."),
  bank_branch_code: yup.string().required("This field is required."),
  bank_account_number: yup.string().required("This field is required."),
  organisation: yup
    .string()
    .oneOf([
      "Umgalelo Products",
      "Oak Education",
      "youth@WORK",
      "Loxion Mobile Library",
    ])
    .required("This field is required."),
});

export const PromoterAccountInitialValues = {
  firstname: "",
  lastname: "",
  email: "",
  countryCallingCode: "",
  phone: "",
  address: "",
  houseno: "",
  postcode: "",
  town: "",
  country: "",
  id_number: "",
  account_type: "",
  bank_name: "",
  bank_branch_code: "",
  bank_account_number: "",
  organisation: "",
};

export const PaymentOnlyPromoterAccountSchema = yup.object().shape({
  id_number: yup.number().required("This field is required."),
  account_type: yup
    .string()
    .oneOf(["Cheque/Current", "Savings", "Other"])
    .required("This field is required."),
  bank_name: yup.string().required("This field is required."),
  bank_branch_code: yup.string().required("This field is required."),
  bank_account_number: yup.string().required("This field is required."),
});

export const PaymentOnlyPromoterAccountInitialValues = {
  id_number: "",
  account_type: "",
  bank_name: "",
  bank_branch_code: "",
  bank_account_number: "",
};

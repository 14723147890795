import React, { useEffect } from "react";
import { removeCookies } from "../../shop/productHelpers";

import "./successful.css";

const Successful = () => {
  useEffect(() => {
    removeCookies();
  }, []);

  return (
    <section id="successful_promoter" className="page-gradient-bg bt-successful_promoter">
      <div className="successful_promoter_page_container">
        {/* icon */}
        <img
          src={require("../../../../assets/thankYou/celebrate.png")}
          alt="Celebrate"
          className="celebrate_img"
        />

        <div className="page_headings_container">
          <h1 className="page_heading">Congratulation! You have successfully 
          signed up as a youthPROFILER promoter</h1>
          <br />
          <p className="heading_sub_info red">
            {" "}
            You are now a youthPROFILER promoter!
          </p>
          <br />
          <div className="heading_sub_info">
          <p className="heading_sub_info red">
            {" "}
            You will receive an email with your promoter link
          </p>

          <br/>
          Do you want to earn some extra cash? By sharing your promoter link 
          with your friends, family, or anyone, you can earn a commission and 
          get paid! It's our way of thanking you for spreading the word 🧡. 
          Sharing your link is easy - just copy it and start sharing! So, start 
          sharing your unique link today and start earning money 😎.
          <br/>
          
            <br />
            If you are interested in purchasing an assessment for your child, visit our 
            <a href="app.sayouthatwork.com" className="red">
            {" "}
            shop
            </a>
            <br />
            <br />
            If you have not received your promoter link via email, please
            check in your SPAM folder. If you still don’t find the emails
            contact us on
            <a href="mailto:youthprofiler@sayouthatwork.com" className="red">
              {" "}
              youthprofiler@sayouthatwork.com
            </a>
            .
          </div>
        </div>
      </div>
    </section>
  );
};

export default Successful;

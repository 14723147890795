import React from "react";

const AcceptTerms = ({ id, accept, setAccept }) => {
  return (
    <div className="checkbox-block fifty">
      <input
        type="checkbox"
        id={id}
        name={id}
        defaultChecked={accept}
        onChange={(e) => {
          setAccept(e.target.checked);
        }}
      />
      <label htmlFor={id}>
        <span>
          I accept the youth@WORK <a href="#"> terms and conditions</a>
        </span>
      </label>
    </div>
  );
};

export default AcceptTerms;

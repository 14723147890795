import { base_url, getOrderAgainstPromoterCode } from "../../../base/api_urls";

export const getOrderData = async (promoter_id) => {
  const endpoint = `${base_url}${getOrderAgainstPromoterCode}?promoter_id=${promoter_id}`;
  try {
    const response = await fetch(endpoint, {
      method: "GET",
    });
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        "Request failed with status " +
          response.status +
          ": " +
          errorResponse.message
      );
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
  }
};

import React from "react";

import PromoterForm from "../account/forms/promoter.form";

import "./promoter.scss";

const PromoterSignupForm = () => {
  return (
    <div className="promoter">
      <PromoterForm />
    </div>
  );
};

export default PromoterSignupForm;

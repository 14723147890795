import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./form.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AppContextProvider } from "./context/appContext";
import { OrdersContextProvider } from "./context/orders.context";
import { ChildrenContextProvider } from "./context/children.context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AppContextProvider>
      <OrdersContextProvider>
        <ChildrenContextProvider>
          <App />
        </ChildrenContextProvider>
      </OrdersContextProvider>
    </AppContextProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

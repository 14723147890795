import { base_url, confirmOrderEndpoint } from "../../../base/api_urls";
import Cookies from "js-cookie";

export const paymentButtonForPayFast = (
  user_id,
  order_id,
  coupon,
  products,
  setPayFast
) => {
  if (!user_id || !order_id || !products || !Object.keys(products).length)
    return;

  const parsedProducts = [];

  Object.keys(products).forEach((key) => {
    const { id, quantity } = products[key];
    parsedProducts.push({ id, qty: quantity });
  });

  const body = {
    user_id,
    coupon,
    orderid: order_id,
    products: parsedProducts,
  };

  // Check for promote_code
  const promoter_code = Cookies.get("promoter_code");
  console.log(promoter_code);
  if (!isNaN(promoter_code)) body["promoter_code"] = promoter_code;

  console.log(body);

  fetch(base_url + confirmOrderEndpoint, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      setPayFast(data);
    })
    .catch((error) => {
      console.error(error);
    });
};

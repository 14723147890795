import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";

function PromoterPrivateRoute({ children }) {
  let auth = Cookies.get("promoterAuthToken");
  if (!auth) return <Navigate to="/promoter_dashboard_login" />;
  else return children;
}

export default PromoterPrivateRoute;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/appContext";
import { saveOrderIdInCookies } from "../../../auth/checkLoginCookie";

import Breadcrumbs from "../../global/breadcrumbs/breadcrumbs";

import {
  calculateTotalCost,
  readExistingSelectedProducts,
} from "../shop/productHelpers";
import { realFormatter } from "../../../assets/currency/realFormatter";
import { fetchUserDataInCookies } from "../../../auth/checkLoginCookie";
import { paymentButtonForPayFast } from "./payFast.service";

import "./orderConfirmation.css";

const OrderConfirmation = () => {
  const navigate = useNavigate();

  const [appContext] = useContext(AppContext);

  const [taxCost, setTaxCost] = useState(0);
  const [overallCost, setOverallCost] = useState(0);
  const [proceedClicked, setProceedClicked] = useState(false);
  const [conditionsAccepted, setConditionsAccepted] = useState(false);
  const [order_id, set_order_id] = useState(0);
  const [payFast, setPayFast] = useState("");

  const handleChangecheck = () => {
    setConditionsAccepted((current) => !current);
  };

  useEffect(() => {
    const selectedProducts =
      appContext?.selectedProducts ?? readExistingSelectedProducts();

    const storedUserData = appContext?.user_data ?? fetchUserDataInCookies();
    if (!storedUserData || !selectedProducts) {
      navigate("/payment_details");
      return;
    }
    const order_Id = storedUserData.orderid;

    if (!order_Id) {
      navigate("/payment_details");
      return;
    }

    //console.log("Order id is: ", order_id);
    set_order_id(order_Id);
    saveOrderIdInCookies(order_Id);

    const userCountry = storedUserData.country;

    const totalCost =
      storedUserData.coupon === "sandbox"
        ? 0
        : calculateTotalCost(appContext.selectedProducts);

    let taxPercent = 0;
    if (userCountry.toLowerCase() === "za") taxPercent = 0.15;

    const taxed = totalCost * taxPercent;
    setTaxCost(taxed);
    setOverallCost(totalCost);

    paymentButtonForPayFast(
      storedUserData.userid,
      order_Id,
      storedUserData.coupon,
      appContext.selectedProducts,
      setPayFast
    );
  }, [appContext.selectedProducts]);

  //console.log("Cookie id is: ", fetchOrderIdInCookies());

  return (
    <div className="min_container">
      <Breadcrumbs active={2} />
      <h1>Order Confirmation</h1>
      <p className={"heading_sub_info"}>
        Please confirm your order and select the preferred payment method.
      </p>
      <div className="order_summary_container">
        <div className="order_information">
          <div className="order_information_item">
            <div className="order_information_item_title"> {"Order Id"} </div>
            <div className="order_information_item_value">
              {Object.keys(appContext.selectedProducts).map(
                (product, index) => {
                  return <p key={index}>{"YP" + order_id}</p>;
                }
              )}
            </div>
          </div>
          <div className="order_information_item">
            <div className="order_information_item_title">{"Product Name"}</div>
            <div className="order_information_item_value">
              {Object.keys(appContext.selectedProducts).map(
                (product, index) => {
                  return (
                    <p key={index}>
                      {appContext.selectedProducts[product].productname}
                    </p>
                  );
                }
              )}
            </div>
          </div>
          <div className="order_information_item">
            <div className="order_information_item_title">
              {"Total quantity"}
            </div>
            <div className="order_information_item_value">
              {Object.keys(appContext.selectedProducts).map(
                (product, index) => {
                  return (
                    <p key={index}>
                      {appContext.selectedProducts[product].quantity}
                    </p>
                  );
                }
              )}
            </div>
          </div>
          <div className="order_information_item">
            <div className="order_information_item_title">{"Unit Price"}</div>
            <div className="order_information_item_value">
              {Object.keys(appContext.selectedProducts).map(
                (product, index) => {
                  return (
                    <p key={index}>
                      {realFormatter.format(
                        appContext.selectedProducts[product].price
                      )}
                    </p>
                  );
                }
              )}
            </div>
          </div>
          <div className="order_information_item">
            <div className="order_information_item_title">{"Total price"}</div>
            <div className="order_information_item_value">
              <div>
                {Object.keys(appContext.selectedProducts).map(
                  (product, index) => {
                    return (
                      <p key={index}>
                        {realFormatter.format(
                          appContext.selectedProducts[product].price *
                            appContext.selectedProducts[product].quantity
                        )}
                      </p>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Order-confirmation-price-block">
        <div className="Order-confirmation-price-items">
          <div className="Order-confirmation-value-block">
            <div className="Ocon-value-title">
              {"*VAT of 15% only applicable in South Africa"}
            </div>
          </div>
        </div>

        <div className="Order-confirmation-price-items">
          <div className="Order-confirmation-value-block">
            <div className="Ocon-value-title">{"VAT (15%):"}</div>
            <div className="Ocon-value">{realFormatter.format(taxCost)}</div>
          </div>
        </div>

        <div className="Order-confirmation-price-items">
          <div className="Order-confirmation-value-block text-right">
            {overallCost === 0 && (
              <div className="Ocon-value">{"(Voucher Applied)"}</div>
            )}
            {overallCost > 0 && (
              <div className="Ocon-value">
                {realFormatter.format(overallCost)}
              </div>
            )}
          </div>
          <div className="Order-confirmation-value-block text-right">
            <div className="Ocon-value-title">{"Total Cost"}</div>
          </div>
        </div>

        <div
          className={"Order-confirmation-price-items"}
          style={{
            opacity: !conditionsAccepted && 0.5,
            pointerEvents: !conditionsAccepted && "none",
          }}
          dangerouslySetInnerHTML={{ __html: payFast }}
        ></div>
      </div>

      <div className="checkbox_container">
        <div
          className={`checkbox-block ${
            proceedClicked && !conditionsAccepted
              ? "order-confirm-not-checked"
              : "order-confirm-checked"
          }`}
        >
          <input
            id="Business"
            type="checkbox"
            name="consent"
            value={conditionsAccepted}
            onChange={(e) => {
              handleChangecheck(e);
            }}
          />
          <label htmlFor="Business">
            I accept the youth@WORK <strong>terms and conditions</strong>
          </label>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;

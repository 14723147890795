import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";

import PromoterLoginBox from "../promoterLoginBox/promoterLoginBox";
import Modal from "../../../global/modal/modal";

import styles from "./promoterLogin.module.scss";

const PromoterLogin = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [reLoginAttempModal, open_reLoginAttempModal] = useState(false);
  const [linkSent, setLinkSent] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  if (token) {
    Cookies.set("promoterAuthToken", token, { expires: 1 });
  }

  const promoterAuthToken = Cookies.get("promoterAuthToken");
  if (promoterAuthToken) {
    navigate("/promoter_dashboard");
  }

  const magicLinkSendCallback = (email) => {
    setUserEmail(email);
    setLinkSent(true);
  };

  return (
    <>
      <div className={styles.login}>
        <h1 className="red">Welcome!</h1>
        <p className="heading_sub_info">Please enter your email to login.</p>
        <PromoterLoginBox setLinkSent={magicLinkSendCallback} />
        <p className="help_text">
          Did not receive an email?{" "}
          <span
            className={`red ${styles.try_again}`}
            onClick={() => open_reLoginAttempModal(true)}
          >
            Click here
          </span>
        </p>
      </div>

      <Modal
        open={reLoginAttempModal && !linkSent}
        closer={open_reLoginAttempModal}
      >
        <div className={`${styles.login} ${styles.dynamic_height}`}>
          <h1>Request new Code</h1>
          <p className="heading_sub_info">
            Please enter your email address and we will send you a new code.
          </p>
          <PromoterLoginBox setLinkSent={magicLinkSendCallback} />
          <p className="help_text">
            Need more help?
            <a href="mailto:youthprofiler@sayouthatwork.com">
              {" "}
              <span className={`red ${styles.try_again}`}>
                youthprofiler@sayouthatwork.com
              </span>
            </a>
          </p>
        </div>
      </Modal>

      <Modal open={linkSent} closer={setLinkSent}>
        <div className={`${styles.login} ${styles.dynamic_height}`}>
          <h1>Link Sent!</h1>
          <p className="heading_sub_info" style={{ textAlign: "center" }}>
            Please check your email <span className="red">{userEmail}</span>{" "}
            Your login link has been sent to you.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default PromoterLogin;

import React, { useState, useContext, useEffect } from "react";

import { ChildrenContext } from "../../../../context/children.context";

import Modal from "../../../global/modal/modal";
import { toast } from "react-toastify";

import {
  removeLinkChildToProduct,
  getAllParentChildLinks,
  linkChildToProduct,
} from "../dashboard.service";

import { checkLoginCookie } from "../../../../auth/checkLoginCookie";

import { base_url, fetchAssessmentURL } from "../../../../base/api_urls";

import { forward_svg } from "../../../../assets/svgs";

import CheckboxWithLabel from "../../../global/checkbox/checkboxWithLabel";

const Assessment = ({
  styles,
  id,
  qty,
  product,
  openExpireModal,
  openSoonModal,
  child,
  assessments,
  fetchFreshData,
}) => {
  const [childrenContext] = useContext(ChildrenContext);
  const [linked_children, set_linked_children] = useState([]);
  const [child_options, set_child_options] = useState([]);
  const [assessmentURL, setAssessmentURL] = useState("1");
  const [availableQty, setAvailableQty] = useState(qty);

  const [assignLicenseModal, set_assignLicenseModal] = useState(false);
  const [checked, setChecked] = useState([]);
  const [termsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    getAllParentChildLinks(id, set_linked_children);
  }, [id]);

  useEffect(() => {
    set_child_options(childrenContext.all_childs);
  }, [childrenContext.all_childs]);

  const checkIfChildExists = (child_id) => {
    return linked_children.filter(
      (c) => c.child_id === child_id || c.id === child_id
    );
  };

  /*const removeChild = async (child, index) => {
    let child_id = child.hasOwnProperty("child") ? child.id : child.child_id;
    await removeLinkChildToProduct(id, child_id);

    fetchFreshData();

    const temp = [...linked_children];
    const removedItem = temp.splice(index, 1);
    setTermsChecked(false);
    setChecked([]);
    set_child_options([...child_options, removedItem[0]]);
    set_linked_children(temp);
    setAvailableQty(availableQty + 1);
  };*/

  const addChild = async () => {
    const new_child_options = [];
    const new_linked_children = [];

    child_options.forEach((child_option) => {
      if (checked.includes(child_option.id)) {
        new_linked_children.push(child_option);
      } else {
        new_child_options.push(child_option);
      }
    });

    set_child_options(new_child_options);
    set_linked_children([...linked_children, ...new_linked_children]);
    set_assignLicenseModal(false);

    try {
      const loading_toast = await linkChildToProduct(id, checked);
      await loadAssessmentURL(loading_toast);
    } catch (err) {
      console.log(err);
    }
  };

  const loadAssessmentURL = async (loading_toast) => {
    if (!child && childrenContext.all_childs.length === 0) return;
    //if (assessmentURL) return;

    fetch(base_url + fetchAssessmentURL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${checkLoginCookie()}`,
      },
      body: JSON.stringify({
        product_id: id,
        child_ids: checked,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data || data?.code || data?.message) {
          setAssessmentURL("-");
          return;
        }

        setAssessmentURL(data);
        setAvailableQty(availableQty - checked.length);
        fetchFreshData();
        toast.dismiss(loading_toast);
      });
  };

  return (
    <div className={styles.assesment}>
      <div className={styles.thumbnail}>
        <img src={product.product_image.url} alt="Assesment" />
      </div>
      {
        <div className={`${styles.details} ${child ? styles.child : ""}`}>
          <h1 className={`red ${styles.heading}`}>{product.productname}</h1>
          <p className="help_text">{product.description}</p>
          {/* <div className={styles.completion_container}>
          <span className={styles.completion}>10% Complete</span>
  </div> }
        {!child && linked_children.length > 0 && (
          <div className={styles.linked}>
            <p className="help_text" style={{ marginBottom: "10px" }}>
              Assigned Licences
            </p>
            <div className={styles.children_list}>
              {linked_children.map((child, index) => {
                return (
                  <div key={index} className={styles.child}>
                    {child.first_name}
                    <button
                      className={styles.remove}
                      onClick={() => {
                        removeChild(child, index);
                      }}
                    >
                      <svg
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.43934 0.93934C1.02513 0.353553 1.97487 0.353553 2.56066 0.93934L6 4.37868L9.43934 0.93934C10.0251 0.353553 10.9749 0.353553 11.5607 0.93934C12.1464 1.52513 12.1464 2.47487 11.5607 3.06066L8.12132 6.5L11.5607 9.93934C12.1464 10.5251 12.1464 11.4749 11.5607 12.0607C10.9749 12.6464 10.0251 12.6464 9.43934 12.0607L6 8.62132L2.56066 12.0607C1.97487 12.6464 1.02513 12.6464 0.43934 12.0607C-0.146447 11.4749 -0.146447 10.5251 0.43934 9.93934L3.87868 6.5L0.43934 3.06066C-0.146447 2.47487 -0.146447 1.52513 0.43934 0.93934Z"
                          fill="#D80027"
                        />
                      </svg>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
            )*/}
        </div>
      }
      {!child && (
        <div className={styles.action}>
          <div className={styles.available}>
            <p className={styles.count}>{availableQty}</p>
            <p className="help_text">Available licenses</p>
          </div>

          <button
            className="btn primary"
            disabled={
              childrenContext.all_childs.length === 0 || availableQty === 0
                ? true
                : false
            }
            onClick={(e) => {
              e.preventDefault();
              set_assignLicenseModal(true);
            }}
          >
            Assign License to Child
          </button>
        </div>
      )}

      <Modal open={assignLicenseModal} closer={set_assignLicenseModal}>
        {" "}
        <h1 className="form_heading_area">Assign License to Children</h1>
        <p className="heading_sub_info center">
          Please select all children you want to assign a license to.
        </p>
        <form className={styles.assign_license_form}>
          {child_options.map((child, index) => {
            if (checkIfChildExists(child.id).length !== 0) return;
            return (
              <CheckboxWithLabel
                key={child.id}
                id={child.id}
                accept={termsChecked}
                setAccept={setTermsChecked}
                checked={checked}
                setChecked={setChecked}
                style={{ fontSize: "18px" }}
              >
                {child.first_name}
              </CheckboxWithLabel>
            );
          })}

          {childrenContext.all_childs.length > 0 &&
            child_options.length === 0 && (
              <p className={styles.error}>
                All children are assign a license already.
              </p>
            )}
          {childrenContext.all_childs.length > 0 && checked.length === 0 && (
            <p className={styles.error}>Select atleast one license.</p>
          )}
          {(availableQty == 0 || availableQty - checked.length < 0) && (
            <p className={styles.error}>
              Currently that much licenses are not available.
            </p>
          )}

          <button
            type="button"
            onClick={() => {
              addChild();
            }}
            disabled={
              checked.length === 0 ||
              availableQty <= 0 ||
              availableQty - checked.length < 0
            }
            className="primary"
          >
            Assign License
          </button>
        </form>
      </Modal>

      <div className={styles.assessments}>
        <h3 className="red">Active Assessments</h3>
        {assessments.map((assessment, index) => {
          const { created_at, expiry, url, child_table_id } = assessment;

          if (
            !child &&
            childrenContext.selected_child &&
            childrenContext.all_childs.length
          ) {
            const child_id = childrenContext.selected_child;
            if (child_table_id && child_table_id !== child_id) return;
          }

          let child_data = linked_children.find((children) => {
            let child_id = children.hasOwnProperty("child")
              ? children.id
              : children.child_id;
            return child_id == child_table_id;
          });

          return (
            <div key={index} className={styles.assessment_link}>
              <a href={url} target={"_blank"} className="btn primary">
                <span className="time">Name: {child_data?.first_name} </span>
                <span className="time">
                  Start: {new Date(created_at).toLocaleDateString('en-GB')}
                </span>
                <span className="time">
                  Expires: {new Date(expiry).toLocaleDateString('en-GB')}
                </span>
                <span className="action">Open {forward_svg}</span>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Assessment;

import React from "react";

import { realFormatter } from "../../../../../assets/currency/realFormatter";

import Counter from "./counter";

const FullDescription = ({
  updateQuantitywithButton,
  quantity,
  changeQuantityManually,
  styles,
  price,
}) => {
  return (
    <div className={styles.full_description}>
      <div className={styles.header}>
        <h2>Screening for Early Learning Foundational Skills</h2>
        <h2 className={`${styles.cost} red`}>{realFormatter.format(price)}</h2>
      </div>
      <p className="help_text">
        What areas are focused on, and why this is important? The Early Years
        Profiling service helps one understand three areas of the child's basic
        English skills and abilities.
      </p>
      <div className={styles.points}>
        <div className={styles.point}>
          <p className="help_text black">
            <span className="light">1.</span> Early Years Vocabulary – Colours,
            Shapes, Letters and Number Testing:
          </p>
          <p className={`help_text ${styles.point_desc}`}>
            Children learn to identify colours and shapes and classify objects
            around them based on these attributes. Knowing if they have letter
            and number skills that form lifelong learning foundations is also
            essential.
          </p>
        </div>
        <div className={styles.point}>
          <p className="help_text black">
            <span className="light">2.</span> Foundation Literacy Skills –
            Sound-Letter Correspondence, Syllables, Rhyming and Initial Sounds:
          </p>
          <p className={`help_text ${styles.point_desc}`}>
            When children understand that words are comprised of sounds, they
            can transfer this knowledge to reading and writing. Phonemic
            awareness is essential because it is critical to reading and
            spelling success. Children who cannot distinguish and manipulate the
            sounds within spoken language have difficulty recognizing and
            learning the necessary print/sound relationship critical to skilful
            reading and spelling success.
          </p>
        </div>
        <div className={styles.point}>
          <p className="help_text black">
            <span className="light">3.</span> Visual Reasoning:
          </p>
          <p className={`help_text ${styles.point_desc}`}>
            We measure fluid intelligence, which can quickly identify patterns,
            logical rules and trends in new information. Our screening
            identifies causes, integrates this into existing knowledge, and
            applies it to solve problems.
          </p>
        </div>
        <p className="help_text black">Early Childhood Reporting</p>
        <p className="help_text">
          We provide feedback in the form of a report, identifying what has and
          should still be learnt. It does not grade kids, which can have a
          negative impact. Instead, it suggests the child's level, starting with
          the lowest level of Novice and rising to Expert level. The goal is a
          sense of pride as children make minor improvements to their skills. We
          will also produce institutional records for management as evidence of
          efficacy.
        </p>
      </div>

      <div className={styles.footer}>
        <Counter
          updateQuantitywithButton={updateQuantitywithButton}
          quantity={quantity}
          changeQuantityManually={changeQuantityManually}
          styles={styles}
        />
      </div>
    </div>
  );
};

export default FullDescription;

import Cookies from "js-cookie";

export const setLoginCookie = (token) => {
  const authToken = Cookies.set("authToken", token, { expires: 1 });
  return authToken;
};

export const checkLoginCookie = () => {
  const authToken = Cookies.get("authToken");
  return authToken;
};

export const logout = () => {
  Cookies.remove("authToken");
  Cookies.remove("logged_user_data");
  window.location.href = "/";
};

export const saveUserDataInCookies = (user_data) => {
  try {
    Cookies.set("user_data", encodeURIComponent(JSON.stringify(user_data)));
  } catch (error) {
    console.error(error);
  }
};

export const fetchUserDataInCookies = () => {
  try {
    return JSON.parse(decodeURIComponent(Cookies.get("user_data")));
  } catch (error) {
    return false;
  }
};

export const saveLoggedInUserDataInCookies = (user_data) => {
  try {
    Cookies.set(
      "logged_user_data",
      encodeURIComponent(JSON.stringify(user_data)),
      { expires: 1 }
    );
  } catch (error) {
    console.error(error);
  }
};

export const fetchLoggedInUserDataInCookies = () => {
  try {
    return JSON.parse(decodeURIComponent(Cookies.get("logged_user_data")));
  } catch (error) {
    return false;
  }
};

export const saveOrderIdInCookies = (orderId) => {
  try {
    Cookies.set("orderId", encodeURIComponent(JSON.stringify(orderId)));
  } catch (error) {
    console.error(error);
  }
};

export const fetchOrderIdInCookies = () => {
  try {
    return JSON.parse(decodeURIComponent(Cookies.get("orderId")));
  } catch (error) {
    return false;
  }
};

import React from "react";
import classNames from "classnames";
import { ErrorMessage, Field } from "formik";

const InputField = ({
  name,
  type,
  placeholder,
  label,
  form: { errors, touched },
  extraClass = "",
  value = "",
  ...props
}) => {
  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <Field
        {...props}
        type={type ? type : "text"}
        name={name}
        placeholder={placeholder}
        className={classNames(
          "input_field",
          errors[name] && touched[name] ? "input_error" : null
        )}
        autoComplete="off"
      />
      <ErrorMessage component="span" name={name} className="error" />
    </>
  );
};

export default InputField;

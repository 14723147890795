import { base_url, productEndpoint } from "../../../base/api_urls";
import { buildWaitingLoader } from "../../../loaders/requestLoader/waitingLoader";
import Cookies from "js-cookie";

export const fetchProducts = (appContext, setAppContext) => {
  const destroyLoader = buildWaitingLoader();
  fetch(base_url + productEndpoint)
    .then((response) => response.json())
    .then((data) => {
      const storedProducts = readExistingSelectedProducts();
      setAppContext({
        ...appContext,
        products: data,
        selectedProducts: storedProducts ?? {},
      });
      destroyLoader();
    })
    .catch((error) => {
      console.error(error);
      destroyLoader();
    });
};

export const updateSelectedProducts = (
  id,
  pid,
  password,
  productname,
  price,
  quantity,
  appContext,
  setAppContext
) => {
  const currentProducts = { ...appContext.selectedProducts };

  const existingProduct = currentProducts[id];

  if (!existingProduct)
    currentProducts[id] = { id, pid, password, productname, price, quantity };

  if (quantity) currentProducts[id].quantity = quantity;
  else {
    delete currentProducts[id];
  }

  if (Object.keys(currentProducts).length === 0) removeCookies();

  setAppContext({ ...appContext, selectedProducts: currentProducts });
};

export const calculateTotalCost = (seletedProducts) => {
  const products = Object.keys(seletedProducts);
  if (!products.length) return;

  let calculatedCost = 0;
  products.forEach((id) => {
    const { price, quantity } = seletedProducts[id];
    calculatedCost += price * quantity;
  });

  Cookies.set(
    "selectedProducts",
    encodeURIComponent(JSON.stringify(seletedProducts)),
    { expires: 1 }
  );

  return calculatedCost;
};

export const readExistingSelectedProducts = () => {
  const existingProducts = Cookies.get("selectedProducts");
  if (!existingProducts) return false;

  try {
    const productsObject = JSON.parse(decodeURIComponent(existingProducts));
    return productsObject;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const removeCookies = () => {
  Cookies.remove("selectedProducts");
};

import React, { useState, useEffect, useContext } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import classNames from "classnames";
import { toast } from "react-toastify";

import { base_url, updateUserEndpoint } from "../../../../base/api_urls";

import { AppContext } from "../../../../context/appContext";

import { checkLoginCookie } from "../../../../auth/checkLoginCookie";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import countriesList from "../../../../assets/data/countriesList.json";
import { MainAccountSchema, MainAccountInitialValues } from "./accounts.schema";
import { saveUserDataInCookies } from "../../../../auth/checkLoginCookie";

import InputField from "../../../form/inputField/inputField";
import AcceptTerms from "./acceptTerms";

const MainAccountUpdateForm = ({ set_updateAccountModal }) => {
  const [appContext, setAppContext] = useContext(AppContext);
  const [countryCallingCodes, setCountryCallingCodes] = useState([]);
  const [termsChecked, setTermsChecked] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const initialValues = MainAccountInitialValues;

  useEffect(() => {
    if (!countryCallingCodes.length) {
      const countriesCodeList = getCountries();
      let callingCodesList = [];
      for (const countryCode of countriesCodeList) {
        const callingCode = getCountryCallingCode(countryCode);
        if (callingCodesList.indexOf(callingCode) === -1) {
          callingCodesList.push(callingCode);
        }
      }
      callingCodesList.sort((a, b) => a - b);
      setCountryCallingCodes(callingCodesList);
    }
  }, [countryCallingCodes]);

  const existingUserData = appContext?.user_data;
  if (existingUserData) {
    Object.keys(existingUserData).forEach((key) => {
      initialValues[key] = existingUserData[key];
    });
    const existingPhone = initialValues?.phone;
    if (!existingPhone) return;
    if (existingPhone.includes("-"))
      initialValues.phone = existingPhone.split("-")[1];
  }

  const updateUserData = async (values) => {
    values["update"] = true;

    setFormSubmitting(true);

    fetch(base_url + updateUserEndpoint, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${checkLoginCookie()}`,
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        setFormSubmitting(false);
        if (data?.code || data?.message || !data) {
          setFormSubmitting(false);
          return;
        }
        const { userid } = data;
        const user_data = { ...values, userid };
        setAppContext({ ...appContext, user_data });
        saveUserDataInCookies(user_data);
        set_updateAccountModal(false);
        toast.success("Updated!");
      })
      .catch((error) => {
        toast.error("Error updating!");
        console.error(error);
        setFormSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={updateUserData}
      validationSchema={MainAccountSchema}
    >
      {({ values, errors, touched, setFieldValue, handleChange }) => (
        <Form id="salesContact">
          <div className="form_heading_area">
            <h2>Update Account</h2>
            <p className="heading_sub_info">Update your account details</p>
          </div>
          <div className="form_row">
            <div className="form_field">
              <InputField
                name="firstname"
                placeholder={"First Name"}
                label={"First Name"}
                form={{ errors, touched }}
              />
            </div>
            <div className="form_field ">
              <InputField
                name="lastname"
                placeholder={"Last Name"}
                label={"Last Name"}
                form={{ errors, touched }}
              />
            </div>
          </div>
          <div className="form_row">
            <div className="form_field ">
              <InputField
                name="email"
                type="email"
                placeholder={"Email"}
                label={"Email"}
                form={{ errors, touched }}
              />
            </div>
            <div className="form_field phone_field_container">
              <div className="phone_country_code">
                <label htmlFor={"countryCallingCode"}>Code</label>
                <Field
                  as="select"
                  className="input_field"
                  name="countryCallingCode"
                >
                  {countryCallingCodes.map((countryCode) => (
                    <option key={countryCode} value={countryCode}>
                      +{countryCode}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="form_field full_input">
                <InputField
                  name="phone"
                  placeholder={"Tel/Mob"}
                  label={"Tel/Mob"}
                  form={{ errors, touched }}
                />
              </div>
            </div>
          </div>
          <div className="form_row">
            <div className="form_field ">
              <InputField
                name="address"
                placeholder={"Street"}
                label={"Street"}
                form={{ errors, touched }}
              />
            </div>
            <div className="form_field">
              <InputField
                name="houseno"
                placeholder={"House Number"}
                label={"House Number"}
                form={{ errors, touched }}
              />
            </div>
          </div>
          <div className="form_row">
            <div className="form_field ">
              <InputField
                name="postcode"
                placeholder={"Post Code"}
                label={"Post Code"}
                form={{ errors, touched }}
              />
            </div>
            <div className="form_field">
              <InputField
                name="town"
                placeholder={"Town"}
                label={"Town"}
                form={{ errors, touched }}
              />
            </div>
          </div>

          <div className="form_row">
            <div className="form_field">
              <label htmlFor={"country"}>Country</label>
              <Field
                as="select"
                name="country"
                className={classNames(
                  "input_field",
                  errors.country && touched.country ? "input_error" : null
                )}
                onChange={(e) => {
                  setFieldValue(
                    "countryCallingCode",
                    getCountryCallingCode(e.target.value || "CH")
                  );
                  handleChange(e);
                }}
              >
                <option value="" disabled>
                  {"Country"}
                </option>
                {countriesList.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </Field>
              <ErrorMessage component="span" name="country" className="error" />
            </div>

            <div className="form_field flex">
              <AcceptTerms
                accept={termsChecked}
                setAccept={setTermsChecked}
                id={"terms-main-account"}
              />
              <button
                type="submit"
                className="primary"
                style={{
                  pointerEvents: formSubmitting ? "none" : "unset",
                  opacity: formSubmitting ? 0.4 : "",
                }}
                disabled={!termsChecked ? true : false}
              >
                Update Details
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MainAccountUpdateForm;

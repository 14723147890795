import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { AppContext } from "../../../context/appContext";

import Breadcrumbs from "../../global/breadcrumbs/breadcrumbs";
import ProductBox from "./productBox/productBox";
import TotalCost from "./totalCost/totalCost";

import { fetchProducts } from "./productHelpers";

import styles from "./shop.module.scss";

const Shop = () => {
  const { promoter_code } = useParams();
  const [appContext, setAppContext] = useContext(AppContext);

  const { products } = appContext;

  if (!products || !products?.length) {
    fetchProducts(appContext, setAppContext);
  }

  if (promoter_code) {
    Cookies.set("promoter_code", promoter_code, { expires: 365 });
  }

  return (
    <>
      <Breadcrumbs active={0} />
      <h1>Shop</h1>
      <p className={"heading_sub_info"} style={{ marginTop: "2vh" }}>
        All assessments are 100% available and can be done at anytime from home or
        at school.
      </p>
      <p className={"heading_sub_info"} style={{ marginTop: "2vh" }}>
        The license purchase will be valid for a duration of 90 days starting
        from the date of purchase. Once this period expires, the license will no
        longer be active, and you will lose your right to use it. It's important
        to note that each license is intended for one child's use only.
      </p>
      <div className={styles.shop}>
        {products.map((product, index) => {
          return <ProductBox key={index} styles={styles} product={product} />;
        })}
        <div className={styles.totalCost_footer}>
          <div className={styles.inner_space}>
            <TotalCost proceedURL={"/payment_details"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Shop;

import {
  base_url,
  requestMagicLinkEndpoint,
  getAuthTokenEndpoint,
  authMeEndpoint,
  authChildEndpoint,
  getBankingDetailsURL,
} from "../../../base/api_urls";
import {
  setLoginCookie,
  saveLoggedInUserDataInCookies,
  checkLoginCookie,
} from "../../../auth/checkLoginCookie";
import { buildWaitingLoader } from "../../../loaders/requestLoader/waitingLoader";

export const requestMagicLink = (emailOrID, setLinkSent, setChildPW) => {
  fetch(base_url + requestMagicLinkEndpoint + `?emailOrID=${emailOrID}`)
    .then((response) => response.json())
    .then((data) => {
      if (data.success) setLinkSent();
      else setChildPW(true);
    });
};

export const LoginWithToken = (token) => {
  const destroy = buildWaitingLoader();
  fetch(base_url + getAuthTokenEndpoint, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ magic_token: token }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data || data?.message || data?.code) {
        destroy();
        return;
      }
      setLoginCookie(data);
      destroy();
      window.location.href = "/login";
    })
    .catch((error) => {
      console.error(error);
      destroy();
    });
};

export const getUserDataWithAuthToken = (
  navigate = null,
  appContext = null,
  setAppContext = null,
  getChildren = null
) => {
  const destroy = buildWaitingLoader();

  const authToken = checkLoginCookie();
  if (!authToken) {
    destroy();
    return;
  }

  fetch(base_url + authMeEndpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data || data?.message || data?.code) {
        destroy();
        if (navigate) navigate("/login");
        return;
      }
      destroy();
      saveLoggedInUserDataInCookies(data);

      let updatedContext = {
        ...appContext,
        logged_in_data: data,
        user_data: data,
      };
      updatedContext.user_data.firstname = data.first_name;
      updatedContext.user_data.lastname = data.last_name;
      updatedContext.user_data.houseno = data.house_no;
      updatedContext.user_data.postcode = data.pincode;
      updatedContext.child = data.child;
      if (appContext) {
        setAppContext(updatedContext);
      }

      if (navigate) {
        navigate("/account");
      }

      if (getChildren) {
        getChildren();
      }

      getBankingDetails(updatedContext, setAppContext);
    })
    .catch((error) => {
      if (navigate) navigate("/login");
      console.error(error);
    });
};

export const loginChildProcess = (child_id, child_pw) => {
  const destroy = buildWaitingLoader();

  if (!child_id || !child_pw) {
    destroy();
    return;
  }

  fetch(base_url + authChildEndpoint, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ child_id, child_pw }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data || data?.message || data?.code) {
        destroy();
        return;
      }

      setLoginCookie(data?.authToken);
      destroy();
      window.location.href = "/login";
    })
    .catch((error) => {
      console.error(error);
      destroy();
    });
};

const getBankingDetails = (appContext, setAppContext) => {
  const authToken = checkLoginCookie();
  if (!authToken) {
    return;
  }

  fetch(base_url + getBankingDetailsURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data || data?.message || data?.code) {
        return;
      }

      let updatedContext = {
        ...appContext,
        banking_details: data,
      };

      if (appContext) {
        setAppContext(updatedContext);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

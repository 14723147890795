import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../../context/appContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import classNames from "classnames";
import { toast } from "react-toastify";

import { base_url, promoterSignupURL } from "../../../../base/api_urls";

import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import countriesList from "../../../../assets/data/countriesList.json";
import {
  PromoterAccountSchema,
  PromoterAccountInitialValues,
  PaymentOnlyPromoterAccountSchema,
  PaymentOnlyPromoterAccountInitialValues,
} from "./accounts.schema";

import InputField from "../../../form/inputField/inputField";
import AcceptTerms from "./acceptTerms";

const PromoterForm = ({
  updatePaymentOnly = false,
  set_promoterAccountModal,
}) => {
  const navigate = useNavigate();
  const [appContext] = useContext(AppContext);
  const [countryCallingCodes, setCountryCallingCodes] = useState([]);
  const [termsChecked, setTermsChecked] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const initialValues = !updatePaymentOnly
    ? PromoterAccountInitialValues
    : PaymentOnlyPromoterAccountInitialValues;

  useEffect(() => {
    if (!countryCallingCodes.length) {
      const countriesCodeList = getCountries();
      let callingCodesList = [];
      for (const countryCode of countriesCodeList) {
        const callingCode = getCountryCallingCode(countryCode);
        if (callingCodesList.indexOf(callingCode) === -1) {
          callingCodesList.push(callingCode);
        }
      }
      callingCodesList.sort((a, b) => a - b);
      setCountryCallingCodes(callingCodesList);
    }
  }, [countryCallingCodes]);

  const updatePromoterAccount = async (values) => {
    setFormSubmitting(true);
    console.log(values);
    values["sendEmail"] = !updatePaymentOnly;

    fetch(base_url + promoterSignupURL, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        setFormSubmitting(false);
        if (data?.code || data?.message || !data) {
          setFormSubmitting(false);
          toast.error("Error submitting form. Please try again.");
          return;
        }
        console.log(updatePaymentOnly);
        updatePaymentOnly && set_promoterAccountModal(false);
        if (updatePaymentOnly) toast.success("Payment details updated!");
        else toast.success("Signup Successful!");
        navigate("/promoter_successful");
      })
      .catch((error) => {
        toast.error("Error submitting form. Please try again.");
        console.error(error);
        setFormSubmitting(false);
      });
  };

  if (appContext?.banking_details) {
    Object.keys(appContext.banking_details).forEach((key) => {
      initialValues[key] = appContext.banking_details[key];
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={updatePromoterAccount}
      validationSchema={
        !updatePaymentOnly
          ? PromoterAccountSchema
          : PaymentOnlyPromoterAccountSchema
      }
    >
      {({ values, errors, touched, setFieldValue, handleChange }) => (
        <Form id="salesContact">
          <div className="form_heading_area">
            <h2>
              {!updatePaymentOnly ? "Sign Up as a promoter" : "Payment Details"}
            </h2>
            {updatePaymentOnly && (
              <p className="heading_sub_info">
                To get paid ensure your account details are setup
              </p>
            )}
          </div>
          {!updatePaymentOnly && (
            <>
              <div className="form_row">
                <div className="form_field">
                  <InputField
                    name="firstname"
                    placeholder={"First Name"}
                    label={"First Name"}
                    form={{ errors, touched }}
                  />
                </div>
                <div className="form_field ">
                  <InputField
                    name="lastname"
                    placeholder={"Last Name"}
                    label={"Last Name"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>
              <div className="form_row">
                <div className="form_field ">
                  <InputField
                    name="email"
                    type="email"
                    placeholder={"Email"}
                    label={"Email"}
                    form={{ errors, touched }}
                  />
                </div>
                <div className="form_field phone_field_container">
                  <div className="phone_country_code">
                    <label htmlFor={"countryCallingCode"}>Code</label>
                    <Field
                      as="select"
                      className="input_field"
                      name="countryCallingCode"
                    >
                      {countryCallingCodes.map((countryCode) => (
                        <option key={countryCode} value={countryCode}>
                          +{countryCode}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="form_field full_input">
                    <InputField
                      name="phone"
                      placeholder={"Tel/Mob"}
                      label={"Tel/Mob"}
                      form={{ errors, touched }}
                    />
                  </div>
                </div>
              </div>
              <div className="form_row">
                <div className="form_field ">
                  <InputField
                    name="address"
                    placeholder={"Street Name"}
                    label={"Street Name"}
                    form={{ errors, touched }}
                  />
                </div>
                <div className="form_field">
                  <InputField
                    name="houseno"
                    placeholder={"House Number"}
                    label={"House Number"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>
              <div className="form_row">
                <div className="form_field ">
                  <InputField
                    name="postcode"
                    placeholder={"Post Code"}
                    label={"Post Code"}
                    form={{ errors, touched }}
                  />
                </div>
                <div className="form_field">
                  <InputField
                    name="town"
                    placeholder={"Town"}
                    label={"Town"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>
            </>
          )}
          <div className="form_row">
            {!updatePaymentOnly && (
              <div className="form_field">
                <label htmlFor={"country"}>Country</label>
                <Field
                  as="select"
                  name="country"
                  className={classNames(
                    "input_field",
                    errors.country && touched.country ? "input_error" : null
                  )}
                  onChange={(e) => {
                    setFieldValue(
                      "countryCallingCode",
                      getCountryCallingCode(e.target.value || "CH")
                    );
                    handleChange(e);
                  }}
                >
                  <option value="" disabled>
                    {"Country"}
                  </option>
                  {countriesList.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="span"
                  name="country"
                  className="error"
                />
              </div>
            )}
            <div className="form_field">
              <InputField
                name="id_number"
                placeholder={"ID Number"}
                label={"ID Number"}
                form={{ errors, touched }}
              />
            </div>
            {updatePaymentOnly && (
              <div className="form_field">
                <InputField
                  name="bank_account_number"
                  placeholder={"Bank Account Number"}
                  label={"Bank Account Number"}
                  form={{ errors, touched }}
                />
              </div>
            )}
          </div>
          <div className="form_row">
            <div className="form_field">
              <label htmlFor={"accountType"}>Account Type</label>
              <Field
                as="select"
                name="account_type"
                className={classNames(
                  "input_field",
                  errors.account_type && touched.account_type
                    ? "input_error"
                    : null
                )}
              >
                <option value="" disabled>
                  {"Account Type"}
                </option>
                {["Cheque/Current", "Savings", "Other"].map((account_type) => (
                  <option key={account_type} value={account_type}>
                    {account_type}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                component="span"
                name="account_type"
                className="error"
              />
            </div>
            <div className="form_field">
              <InputField
                name="bank_name"
                placeholder={"Bank Name"}
                label={"Bank Name"}
                form={{ errors, touched }}
              />
            </div>
          </div>
          <div className="form_row">
            <div className="form_field">
              <InputField
                name="bank_branch_code"
                placeholder={"Bank Branch Code"}
                label={"Bank Branch Code"}
                form={{ errors, touched }}
              />
            </div>
            {!updatePaymentOnly && (
              <div className="form_field">
                <InputField
                  name="bank_account_number"
                  placeholder={"Bank Account Number"}
                  label={"Bank Account Number"}
                  form={{ errors, touched }}
                />
              </div>
            )}
            {updatePaymentOnly && (
              <div className="form_field">
                <button
                  type="submit"
                  className="primary full"
                  style={{
                    pointerEvents: formSubmitting ? "none" : "unset",
                    opacity: formSubmitting ? 0.4 : "",
                  }}
                  disabled={Object.keys(errors).length > 0 ? true : false}
                >
                  {"Update Details"}
                </button>
              </div>
            )}
          </div>

          {!updatePaymentOnly && (
            <div className="form_row">
              <div className="form_field">
                <label htmlFor={"organisation"}>Organisation</label>
                <Field
                  as="select"
                  name="organisation"
                  className={classNames(
                    "input_field",
                    errors.organisation && touched.organisation
                      ? "input_error"
                      : null
                  )}
                >
                  <option value="" disabled>
                    {"Organisation"}
                  </option>
                  {[
                    "Umgalelo Products",
                    "Oak Education",
                    "youth@WORK",
                    "Loxion Mobile Library",
                  ].map((organisation) => (
                    <option key={organisation} value={organisation}>
                      {organisation}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  component="span"
                  name="organisation"
                  className="error"
                />
              </div>

              <div className="form_field flex">
                <AcceptTerms
                  accept={termsChecked}
                  setAccept={setTermsChecked}
                  id={"terms-main-account"}
                />

                <button
                  type="submit"
                  className="primary"
                  style={{
                    pointerEvents: formSubmitting ? "none" : "unset",
                    opacity: formSubmitting ? 0.4 : "",
                  }}
                  disabled={!termsChecked ? true : false}
                >
                  {"Submit Details"}
                </button>
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default PromoterForm;

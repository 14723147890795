import React, { useState, useEffect, useContext, useRef } from "react";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";

import {
  base_url,
  addChildToParentAccountEndpoint,
  updateChildAccountEndpoint,
} from "../../../../base/api_urls";

import { ChildrenContext } from "../../../../context/children.context";

import { checkLoginCookie } from "../../../../auth/checkLoginCookie";

import {
  ChildAccountSchema,
  ChildAccountUpdateSchema,
  ChildAccountInitialValues,
} from "./accounts.schema";

import InputField from "../../../form/inputField/inputField";
import AcceptTerms from "./acceptTerms";

import { eye_svg, hide_eye_svg } from "../../../../assets/svgs";

const ChildAccountUpdateForm = ({
  child = null,
  resetForm,
  set_resetForm,
  set_helpAddLicenseModal,
  set_childAccountModal,
}) => {
  const [childrenContext, setChildrenContext] = useContext(ChildrenContext);
  const [termsChecked, setTermsChecked] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [initState, setInitState] = useState(ChildAccountInitialValues);
  const [showForm, setShowForm] = useState(false);
  const [child_table_id, set_child_table_id] = useState(0);
  const [passwordType, setPasswordType] = useState("password");

  const formikRef = useRef(null);

  const initialValues = ChildAccountInitialValues;

  console.log(child);

  useEffect(() => {
    setShowForm(false);

    if (child !== null) {
      if (child?.first_name) initialValues.firstname = child.first_name;
      if (child?.last_name) initialValues.lastname = child.last_name;
      if (child?.child_id) initialValues.child_id = child.child_id;

      if (child?.child_pw) {
        initialValues.child_pw = child.child_pw;
      }

      set_child_table_id(child.id);
    } else {
      initialValues.firstname = "";
      initialValues.lastname = "";
      initialValues.child_id = "";
      initialValues.child_pw = "";
    }
    setInitState(initialValues);
    setTimeout(() => setShowForm(true), 10);
  }, [child]);

  useEffect(() => {
    if (resetForm) {
      set_resetForm(!resetForm);
      formikRef.current.resetForm();
    }
  }, [resetForm]);

  const createNewChild = async (values) => {
    console.log("create child", values);

    setFormSubmitting(true);

    fetch(base_url + addChildToParentAccountEndpoint, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${checkLoginCookie()}`,
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        setFormSubmitting(false);
        if (data?.code || data?.message || !data) {
          setFormSubmitting(false);
          return;
        }
        const { all_childs } = childrenContext;
        setChildrenContext({ all_childs: [...all_childs, data] });
        set_childAccountModal(false);
        set_resetForm(true);
        toast.success("New Child added!");
        set_helpAddLicenseModal(true);
        //setShowForm(false); //close the form after child added
      })
      .catch((error) => {
        toast.error("Error adding new child!");
        console.error(error);
        setFormSubmitting(false);
      });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const updateChildData = (values) => {
    const data = { ...values };

    setFormSubmitting(true);

    fetch(base_url + updateChildAccountEndpoint, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${checkLoginCookie()}`,
      },
      body: JSON.stringify({
        ...data,
        child_table_id,
        child_pw: data?.child_pw !== "" ? data.child_pw : null,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFormSubmitting(false);
        if (!data) {
          setFormSubmitting(false);
          return;
        }
        const { all_childs } = childrenContext;
        let childs_copy = [...all_childs];
        childs_copy.forEach((c) => {
          if (c.id === child_table_id) {
            c.first_name = data.first_name;
            c.last_name = data.last_name;
            c.child_id = data.child_id;
          }
        });
        setChildrenContext({ all_childs: [...childs_copy] });
        set_childAccountModal(false);
        toast.success("Child Updated!");
      })
      .catch((error) => {
        toast.error("Error updating child!");
        console.error(error);
        setFormSubmitting(false);
      });
  };

  return (
    <>
      {showForm && (
        <Formik
          initialValues={initState}
          onSubmit={!child ? createNewChild : updateChildData}
          validationSchema={
            !child ? ChildAccountSchema : ChildAccountUpdateSchema
          }
          innerRef={formikRef}
        >
          {({ values, errors, touched }) => (
            <Form id="salesContact">
              <div className="form_heading_area">
                <h2>{!child ? "Create Child Account" : "Update Account"}</h2>
                {child && (
                  <p className="heading_sub_info">
                    Update child account details
                  </p>
                )}
              </div>
              <div className="form_row">
                <div className="form_field">
                  <InputField
                    name="firstname"
                    placeholder={"First Name"}
                    label={"First Name"}
                    form={{ errors, touched }}
                  />
                </div>
                <div className="form_field ">
                  <InputField
                    name="lastname"
                    placeholder={"Last Name"}
                    label={"Last Name"}
                    form={{ errors, touched }}
                  />
                </div>
              </div>
              <div className="form_row">
                <div className="form_field ">
                  <InputField
                    name="child_id"
                    type="text"
                    placeholder={"Child ID Number"}
                    label={"Child ID Number"}
                    form={{ errors, touched }}
                  />
                </div>
                <div className="form_field ">
                  <InputField
                    name="child_pw"
                    type={passwordType}
                    placeholder={"Child Password"}
                    label={"Child Password"}
                    form={{ errors, touched }}
                  />

                  <div
                    onClick={togglePassword}
                    style={{
                      position: "absolute",
                      right: "30px",
                    }}
                  >
                    {passwordType === "password" ? eye_svg : hide_eye_svg}
                  </div>
                </div>
              </div>
              <div className="form_row right">
                <div
                  className="form_field flex right"
                  style={{ marginTop: "20px" }}
                >
                  <AcceptTerms
                    accept={termsChecked}
                    setAccept={setTermsChecked}
                    id={"terms-child-account"}
                  />
                  <button
                    type="submit"
                    className="primary"
                    style={{
                      pointerEvents: formSubmitting ? "none" : "unset",
                      opacity: formSubmitting ? 0.4 : "",
                    }}
                    disabled={!termsChecked ? true : false}
                  >
                    {!child ? "Create Account" : "Update Details"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default ChildAccountUpdateForm;

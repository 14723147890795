export const base_url = "https://xu89-g7gw-gqap.n7.xano.io/";

// Links Of different endpoints
export const productEndpoint = "api:3Oaxa9--/product";
export const paymentDetailsEndpoint = "api:ba3bZT5-/user";
export const updateUserEndpoint = "api:ba3bZT5-/user-update";
export const confirmOrderEndpoint = "api:Xgpm_jJG/order";
export const requestMagicLinkEndpoint = "api:x4ocgg3f/auth/magic-link";
export const getAuthTokenEndpoint = "api:x4ocgg3f/auth/magic-login";
export const authMeEndpoint = "api:x4ocgg3f/auth/me";
export const authChildEndpoint = "api:x4ocgg3f/auth/child-login";
export const getAllChildrenEndpoint = "api:ba3bZT5-/child";
export const getAllCompletedOrdersEndpoint = "api:UcnStS42/getexistingorder";
export const getAllProductsActiveLicenses = "api:UcnStS42/product_licenses";
export const addChildToParentAccountEndpoint = "api:ba3bZT5-/child";
export const updateChildAccountEndpoint = "api:ba3bZT5-/update-child";
export const linkChildToProductEndpoint = "api:UcnStS42/addLinkedChild";
export const removLinkChildToProductEndpoint = "api:UcnStS42/removeLinkedChild";
export const getAllParentChildLinksEndpoint = "api:UcnStS42/getAllChildLinks";
export const checkIfChildExistEndpoint = "api:ba3bZT5-/getChild/";
export const checkIfPromoterExistEndpoint = "api:ru2911Uw/promoters/";
export const fetchAssessmentURL = "api:x5KRmuDD/getAssessmentLink";
export const fetchChildProductsAndAssessmentsURL =
  "api:UcnStS42/child_product_link";
export const promoterSignupURL = "api:ru2911Uw:v1/promoter_signup";
export const getBankingDetailsURL = "api:ru2911Uw:v1/get_banking_details";
export const getOrderAgainstOrderId = "api:Xgpm_jJG/getOrderAgainstOrderId";
export const getOrderAgainstPromoterCode =
  "api:ru2911Uw//promoters/getOrderById";
export const requestMagicLinkEndpointPromoter =
  "api:x4ocgg3f/auth/magic-link-promoter";

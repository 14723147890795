import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import { AppContext } from "../../../../context/appContext";
import { ChildrenContext } from "../../../../context/children.context";

import { getAllChildrenOfAUser } from "../../../pages/dashboard/dashboard.service";
import { getUserDataWithAuthToken } from "../../../pages/login/login.service";

import styles from "./promoterHeader.module.scss";

import logo from "../../../../assets/global/yp_logo.svg";

const PromoterHeader = (props) => {
  const [appContext, setAppContext] = useContext(AppContext);
  const [childrenContext, setChildrenContext] = useContext(ChildrenContext);
  const { logged_in_data } = appContext;

  useEffect(() => {
    if (!logged_in_data)
      getUserDataWithAuthToken(null, appContext, setAppContext, () =>
        getAllChildrenOfAUser(childrenContext, setChildrenContext)
      );
  }, []);

  const removePropCookie = () => {
    Cookies.remove("promoterAuthToken");
  };

  return (
    <>
      <nav className={styles.header}>
        <div className={styles.logo}>
          <Link to={"/"}>
            <img src={logo} alt="Youth At Work" />
          </Link>
        </div>
        <div className={styles.links}>
          {!logged_in_data && (
            <Link to={"/promoter_dashboard_login"}>
              <button className={styles.login_btn} onClick={removePropCookie}>
                Logout
              </button>
            </Link>
          )}
        </div>
      </nav>
      {props.children}
    </>
  );
};

export default PromoterHeader;

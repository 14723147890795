import React from "react";

const Counter = ({
  updateQuantitywithButton,
  quantity,
  changeQuantityManually,
  styles,
}) => {
  return (
    <div className={styles.counter}>
      <button
        className={styles.change}
        onClick={() => {
          updateQuantitywithButton(false);
        }}
      >
        -
      </button>
      <input
        value={quantity}
        onChange={changeQuantityManually}
        className={styles.counter_input}
      />
      <button
        className={styles.change}
        onClick={() => {
          updateQuantitywithButton(true);
        }}
      >
        +
      </button>
    </div>
  );
};

export default Counter;

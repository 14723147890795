import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  LoginWithToken,
  requestMagicLink,
  loginChildProcess,
} from "../login.service";

import styles from "./loginBox.module.scss";

const LoginBox = ({ setLinkSent }) => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [enter_child_pw, set_enter_child_pw] = useState(false);

  useEffect(() => {
    if (token) {
      LoginWithToken(token, () => navigate("/dashboard"));
      return;
    }
  }, [token]);

  const LoginUser = (e) => {
    e.preventDefault();
    if (e.target.email.value !== "")
      requestMagicLink(
        e.target.email.value,
        () => {
          setLinkSent(e.target.email.value);
        },
        set_enter_child_pw
      );
  };

  const loginChild = (e) => {
    e.preventDefault();
    loginChildProcess(e.target.email.value, e.target.child_pw.value);
  };

  return (
    <form
      className={styles.loginBox}
      onSubmit={!enter_child_pw ? LoginUser : loginChild}
    >
      <input
        type="text"
        name="email"
        placeholder="Email, ID Number or username"
      />
      {enter_child_pw && (
        <input type="password" name="child_pw" placeholder="Child Password" />
      )}
      <button type="submit" className="primary">
        Login
      </button>
    </form>
  );
};

export default LoginBox;

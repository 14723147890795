import React from "react";

import styles from "./footer.module.scss";

const LINKS = [
  // { url: "/promoter", title: "Sign up as a promoter" },
  { url: "#", title: "Terms of service" },
 // { url: "#", title: "Imprint" },
  { url: "#", title: "Data protection" },
];

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.inner}>
        <div className={styles.side}>
          <p className={styles.text}>youth@WORK © 2023</p>
        </div>
        <div className={`${styles.side} ${styles.service_links}`}>
          {LINKS.map(({ url, title }, index) => {
            return (
              <a
                key={index}
                href={url}
                className={styles.text}
                target={"_blank"}
              >
                {title}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Footer;

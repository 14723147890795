import * as yup from "yup";

export const PaymentDetailsSchemaPersonal = yup.object().shape({
  firstname: yup.string().required("This field is required."),
  lastname: yup.string().required("This field is required."),
  email: yup
    .string()
    .required("This field is required.")
    .matches(
      /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/,
      "Enter valid Email."
    ),
  phone: yup.string().required("This field is required."),
  countryCallingCode: yup.string().required("This field is required."),
  address: yup.string().required("This field is required."),
  houseno: yup.string().required("This field is required."),
  postcode: yup.string().required("This field is required."),
  town: yup.string().required("This field is required."),
  country: yup.string().length(2).required("This field is required."),
});

export const PaymentDetailsSchemaPersonalInitialValues = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  countryCallingCode: "",
  address: "",
  houseno: "",
  postcode: "",
  town: "",
  country: "",
  coupon: "",
};

export const PaymentDetailsSchemaBusiness = yup.object().shape({
  orgname: yup.string().required("This field is required."),
  firstname: yup.string().required("This field is required."),
  lastname: yup.string().required("This field is required."),
  email: yup
    .string()
    .required("This field is required.")
    .matches(
      /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/,
      "Enter Valid Email."
    ),
  phone: yup.string().required("This field is required."),
  countryCallingCode: yup.string().required("This field is required."),
  address: yup.string().required("This field is required."),
  houseno: yup.string().required("This field is required."),
  postcode: yup.string().required("This field is required."),
  town: yup.string().required("This field is required."),
  country: yup.string().length(2).required("This field is required."),
});

export const PaymentDetailsSchemaBusinessInitialValues = {
  orgname: "",
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  countryCallingCode: "",
  address: "",
  houseno: "",
  postcode: "",
  town: "",
  country: "",
  coupon: "",
};

import { toast } from "react-toastify";

import {
  base_url,
  checkIfChildExistEndpoint,
  checkIfPromoterExistEndpoint,
} from "../../../base/api_urls";
import { checkLoginCookie } from "../../../auth/checkLoginCookie";

export const checkIfChildExist = (child_id) => {
  return new Promise((resolve, reject) => {
    fetch(base_url + checkIfChildExistEndpoint + child_id, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${checkLoginCookie()}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch(() => {
        toast.error("Error while checking child id!");
        reject();
      });
  });
};

export const checkIfPromoterExist = (id_number) => {
  return new Promise((resolve, reject) => {
    fetch(base_url + checkIfPromoterExistEndpoint + id_number, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch(() => {
        toast.error("Error while checking ID number!");
        reject();
      });
  });
};

import React from "react";
import { Navigate } from "react-router-dom";
import { checkLoginCookie } from "../../auth/checkLoginCookie";

function PrivateRoute({ children }) {
  let auth = checkLoginCookie();
  if (!auth) return <Navigate to="/login" />;
  else return children;
}

export default PrivateRoute;

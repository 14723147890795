import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LoginWithToken } from "../login.service";
import { requestMagicLinkPromoter } from "../promoterLogin/promoterLogin.service";
import styles from "./promoterLoginBox.module.scss";

const PromoterLoginBox = ({ setLinkSent }) => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  useEffect(() => {
    if (token) {
      LoginWithToken(token, () => navigate("/promoter_dashboard"));
      return;
    }
  }, [token]);

  const loginPromoter = () => {
    requestMagicLinkPromoter(email, () => {
      setLinkSent(email);
    });
  };

  return (
    <form className={styles.loginBox}>
      <input
        type="text"
        name="email"
        placeholder="Email address"
        onChange={(e) => setEmail(e.target.value)}
      />
      <button type="button" className="primary" onClick={() => loginPromoter()}>
        Login
      </button>
    </form>
  );
};

export default PromoterLoginBox;

export const buildWaitingLoader = () => {
  const loader = document.createElement("div");
  loader.className = "loader";
  loader.innerHTML = `<span class="loader_icon"></span>`;
  document.body.appendChild(loader);

  const destroyLoader = () => {
    loader.classList.add("destroy");
    setTimeout(() => {
      loader.remove();
    }, 300);
  };

  return destroyLoader;
};

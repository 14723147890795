import React from "react";

import styles from "./modal.module.scss";

const Modal = ({ children, open, closer, maxWidth = 0, overflow = false }) => {
  const closeModal = (e) => {
    if (!e.target.dataset.popbg) return;
    closer(!open);
  };

  return (
    <div
      className={styles.popup_bg_layer}
      onClick={(e) => closeModal(e)}
      style={{
        display: open ? "flex" : "none",
      }}
      data-popbg="true"
    >
      <div
        className={`${styles.popup_container}`}
        style={{
          maxWidth: maxWidth > 0 ? `${maxWidth}px` : "",
          overflowY: overflow ? "visible" : "",
        }}
      >
        <div
          onClick={() => closer(false)}
          className={`${styles.popup_cancel_button}`}
        >
          <svg
            width="42"
            height="42"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="26" cy="26" r="26" />
            <path
              d="M16.1797 35.2448L35.2464 16.1781M35.2464 35.2448L16.1797 16.1781"
              stroke="white"
              strokeWidth="3"
            />
          </svg>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;

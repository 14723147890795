const CheckboxWithLabel = ({
  id,
  accept,
  setAccept,
  checked,
  setChecked,
  children,
  ...rest
}) => {
  return (
    <div className="checkbox-block fifty" key={id}>
      <input
        type="checkbox"
        id={id}
        name={id}
        defaultChecked={accept}
        onChange={(e) => {
          setAccept(e.target.checked);
          setChecked(
            e.target.checked
              ? [...checked, id]
              : checked.filter((item) => item !== id)
          );
        }}
      />
      <label htmlFor={id} {...rest}>
        {children}
      </label>
    </div>
  );
};

export default CheckboxWithLabel;

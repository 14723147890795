import React, { useState, useContext, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";

import { AppContext } from "../../../context/appContext";
import { ChildrenContext } from "../../../context/children.context";

import { logout } from "../../../auth/checkLoginCookie";
import { getAllChildrenOfAUser } from "../../pages/dashboard/dashboard.service";
import { getUserDataWithAuthToken } from "../../pages/login/login.service";

import styles from "./header.module.scss";

import logo from "../../../assets/global/yp_logo.svg";

const activeClassName = styles.active;

const Header = () => {
  const [appContext, setAppContext] = useContext(AppContext);
  const [childrenContext, setChildrenContext] = useContext(ChildrenContext);
  const [showChildrenList, setShowChildrenList] = useState(false);

  const { logged_in_data } = appContext;
  const { all_childs } = childrenContext;

  const [selectedChildName, setSelectedChildName] = useState("");

  useEffect(() => {
    if (!logged_in_data)
      getUserDataWithAuthToken(null, appContext, setAppContext, () =>
        getAllChildrenOfAUser(childrenContext, setChildrenContext)
      );
  }, []);

  useEffect(() => {
    if (
      childrenContext?.selected_child !== null ||
      childrenContext?.selected_child !== undefined
    ) {
      const findChild = all_childs.filter(
        (child) => child.id === childrenContext.selected_child
      );

      if (findChild.length > 0) setSelectedChildName(findChild[0].first_name);
    }
  }, [childrenContext?.selected_child]);

  return (
    <nav className={styles.header}>
      <div className={styles.logo}>
        <Link to={"/"}>
          <img src={logo} alt="Youth At Work" />
        </Link>
      </div>
      <div className={styles.links}>
        <a
          href="https://www.sayouthatwork.com/youthprofiler"
          target={"_blank"}
          referrerPolicy={"no-referrer"}
        >
          About
        </a>
        {logged_in_data && !logged_in_data?.child && (
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
          >
            Shop
          </NavLink>
        )}
        {logged_in_data && !logged_in_data?.child && (
          <NavLink
            to="/account"
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
          >
            Account
          </NavLink>
        )}
        {logged_in_data && (
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive ? activeClassName : undefined
            }
          >
            Dashboard
          </NavLink>
        )}
        {/*logged_in_data && (
          <div className={styles.accounts}>
            <a
              href="#"
              className={styles.selected}
              onClick={(e) => {
                e.preventDefault();
                setShowChildrenList(!showChildrenList);
              }}
            >
              {!selectedChildName && logged_in_data.first_name}{" "}
              {selectedChildName && selectedChildName}
              {!logged_in_data?.child && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 9L12.7071 14.2929C12.3166 14.6834 11.6834 14.6834 11.2929 14.2929L6 9"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </a>
            {!logged_in_data?.child && (
              <div
                className={styles.children}
                style={{ display: showChildrenList ? "block" : "none" }}
              >
                {selectedChildName && (
                  <p
                    className={styles.child}
                    onClick={() => {
                      setShowChildrenList(false);
                      setSelectedChildName();
                      setChildrenContext({
                        ...childrenContext,
                        selected_child: 0,
                      });
                    }}
                  >
                    {logged_in_data?.first_name}
                  </p>
                )}
                {all_childs &&
                  all_childs.map(({ id, first_name }, index) => {
                    return (
                      <p
                        key={id}
                        className={`${styles.child} ${
                          id === childrenContext.selected_child ||
                          index === childrenContext.selected_child
                            ? styles.selected_child
                            : ""
                        }`}
                        onClick={() => {
                          console.log("clicked by child");
                          setShowChildrenList(false);
                          if (id === childrenContext.selected_child) {
                            // setChildrenContext({
                            //   ...childrenContext,
                            //   selected_child: 0,
                            // });
                            return;
                          }
                          setChildrenContext({
                            ...childrenContext,
                            selected_child: id,
                          });
                        }}
                      >
                        {first_name}
                      </p>
                    );
                  })}

                <Link to="/account" style={{ margin: 0 }}>
                  <button
                    className={styles.add_child}
                    onClick={() => setShowChildrenList(false)}
                  >
                    <svg
                      width="37"
                      height="36"
                      viewBox="0 0 37 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="18.5"
                        cy="18"
                        r="18"
                        fill="#F65B5B"
                        fillOpacity="0.14"
                      />
                      <path
                        d="M10.5 16.5C9.67157 16.5 9 17.1716 9 18C9 18.8284 9.67157 19.5 10.5 19.5V16.5ZM26.5 19.5C27.3284 19.5 28 18.8284 28 18C28 17.1716 27.3284 16.5 26.5 16.5V19.5ZM20 10C20 9.17157 19.3284 8.5 18.5 8.5C17.6716 8.5 17 9.17157 17 10L20 10ZM17 26C17 26.8284 17.6716 27.5 18.5 27.5C19.3284 27.5 20 26.8284 20 26H17ZM10.5 19.5H26.5V16.5H10.5V19.5ZM17 10L17 26H20L20 10L17 10Z"
                        fill="#D80027"
                      />
                    </svg>
                  </button>
                </Link>
              </div>
            )}
          </div>
                )*/}
        {!logged_in_data && (
          <Link to={"/login"}>
            <button className={styles.login_btn}>Login</button>
          </Link>
        )}
        {logged_in_data && (
          <button className={styles.login_btn} onClick={logout}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 3H13C9.22876 3 7.34315 3 6.17157 4.17157C5 5.34315 5 7.22876 5 11V13C5 16.7712 5 18.6569 6.17157 19.8284C7.34315 21 9.22876 21 13 21H14"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M11 12H21"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M21 12L17 8"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M21 12L17 16"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>{" "}
            <span>Logout</span>
          </button>
        )}
      </div>
    </nav>
  );
};

export default Header;
